import React from 'react'
import { Row,Col} from 'react-bootstrap'
import '../../sass/invstyle.scss'

function Privacy() {
  return (
    <div>
          <h1 style={{marginTop:"5%"}} className='grad'>Privacy Policy</h1>

            <div style={{margin:"0% 5% 5% 5%",textAlign:"left"}} >

            <p className='fs'>Privacy Policy effective from 1st Jan 2020</p>
            <p>The privacy policy governs the collection, usage and sharing of private information provided by the user to E Drives. User privacy is a top priority for E Drives and all efforts will be made to prevent unauthorized use of any private information divulged to E Drives. E Drives collects information from its users for the delivery and improvement of services and for the purpose of user safety. A user must be in agreement with the terms and conditions of this privacy policy in order to avail E Drives services. The user is free to suspend or terminate the services of E Drives by revoking his consent to the privacy policy or by deleting his account.</p>
            <p className='fs'>PERSONAL INFORMATION</p>
            <p>
                <ul>
                    <li> E Drives collects certain information from its users for the purpose of ‘user identification’. The following information is collected by E Drives when an applicant applies for membership on its website or smartphone based applications:
                        <ol>
                            <li> Name</li>
                            <li>Contact Number</li>
                            <li>Postal Address</li>
                            <li> E-mail Address</li>
                        </ol>
                    </li>
                    <li>By providing the above information, the applicant has voluntarily agreed to the terms and condition of this document.</li>
                    <li>The following information is collected from the user during the rental period:</li>
                    <ol>
                        <li> Location: E Drives has installedGPS tracking devices in all its vehicles in order to ensure the safety of the passengers and the vehicle. The location of all E Drives vehicles is tracked in real-time. The information will not be divulged publicly. However, the information may be shared with the concerned authorities such as the police or similar parties in the event of an accident or mishap. E Drives reserves the right to share any information pertaining to the location of the vehicle with insurance companies in the event of any damage/accident claim or theft.</li>
                        <li> Vehicle Use Data: Data related to vehicle use is collected and monitored by E Drives in order to prevent misuse and theft of the vehicle.</li>
                    </ol>
                    <li>E Drives has the right to access a user’s driver record and vehicle history from the concerned authorities. By becoming a member of E Drives, the user authorizes all DMV’s, RMV’s, automobile mechanics and any relevant authority to release the user’s driving records and vehicle history.</li>
                </ul>
            </p>
            <p className='fs'>NON PERSONAL INFORMATION</p>
                <ul>
                    <li>E Drives collects non-personal information which has no direct relation to any specific individual; including but not limited to IP addresses, usage details and identifying technologies. E Drives reserves the right to collect, use, transfer and disclose non-personal information for any purpose. In the event of combining personal and non-personal information, both will be treated as personal.</li>
                </ul>
            <p className='fs'>COOKIES</p>
            <ul>
                <li>Cookies are used by E Drives to collect non-identifiable information. A cookie is a piece of data that a website can send to your browser, which may then be stored on your computer as an anonymous tag that identifies your computer but not you. E Drives uses cookies on some pages of its website with the intent to enhance service. The information is shared with third party vendors. The user is free to disable the sharing of cookies on his/her web browser, however this may lead to hindrance in a few services.</li>
            </ul>
            <p className='fs'>CONTACT US</p>
            <ul>
                <li>We have designated an individual who is accountable for compliance with privacy standards. If you have questions about our Privacy Policy or our use of your information, or if you need help changing your personal information or to opt-out, please contact us by sending an email ------------- .</li>
            </ul>
            <p>E Drives reserves the right to change its Privacy Policy at any point of time. The user shall be notified in such an event through our website and/or by an e-mail. In the event of any disagreement, the user is free to suspend or terminate E Drives services by deleting his/her account.</p>
           
            </div>
    </div>
  )
}

export default Privacy