import React,{useState} from "react";
import Button from 'react-bootstrap/Button';
import {Container,Form} from 'react-bootstrap';
import instance from "../helpers/instance";

function Manualinvestment() {


  const [formdata, setformdata] = useState({
    invoice: '',
    leaseagreement: '',
    email: '',
    token: '',
    noofbikes: '',
    investmentamount: '',
    investmentdate: '',
    rateofbike: '',
    paymentinstrument: 'manual',
    bikenumber: '',
    nextpayout: '',
    tenure: '',
    payoutamount: '',
    rateofinterest: '',
    investmentnumber: '',
    payoutremaining: '',
    totalnoofpayouts: '',
    
    
  });
    
    const onSubmit= async (e)=> {
        e.preventDefault()
      const formData = new FormData()
      for (var i in formdata)
      {
        console.log(i+'->'+formdata[i]);
        
        formData.append(i, formdata[i]);
        }
      // formData.append({formdata});
      // formData.append('invoice', formdata.invoice);
      // formData.append('leaseagreement', formdata.leaseagreement);
      // formData.append('email', formdata.email);
      // console.log("formdata",formdata);
      // console.log("formData",formData);
      const senddata = await instance.post("/api/investmentdetails/manualinvestment", formData);
      console.log(senddata)
      alert('sent');
    }

    return (
      <Container style={{width:"80%"}}>
            
    <Form onSubmit={onSubmit}>
      <Form.Group className="mb-3" controlId="formBasic">
        <Form.Label>Email address<br/><p>[e.g. - rahul@gmail.com ]</p></Form.Label>
        <Form.Control type="email" placeholder="Enter email" onChange={e=>setformdata({...formdata,email:e.target.value})}/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasic">
        <Form.Label>Token<br/><p>[Secret code e.g. - nlkjnlj]</p></Form.Label>
        <Form.Control type="text" placeholder="Enter token" onChange={e=>setformdata({...formdata,token:e.target.value})}/>
          </Form.Group>
           <Form.Group className="mb-3" controlId="formBasic">
        <Form.Label>Number of bikes<br/><p>[e.g. - 5 ]</p></Form.Label>
        <Form.Control type="text" placeholder="Enter number of bikes" onChange={e=>setformdata({...formdata,noofbikes:e.target.value})}/>
          </Form.Group>
            
           <Form.Group className="mb-3" controlId="formBasic">
        <Form.Label>Investment amount<br/><p>[e.g. - noofbikes*costperbike]</p></Form.Label>
        <Form.Control type="number" placeholder="investment amount" onChange={e=>setformdata({...formdata,investmentamount:e.target.value})}/>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasic">
        <Form.Label>Investment date<br/><p>[date of investment]</p></Form.Label>
        <Form.Control type="date" placeholder="investment date" onChange={e=>setformdata({...formdata,investmentdate:e.target.value})}/>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasic">
        <Form.Label>Rate of bike <br/><p>[rate per bike ]</p></Form.Label>
        <Form.Control type="number" placeholder="rate of bike" onChange={e=>setformdata({...formdata,rateofbike:e.target.value})}/>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasic">
        <Form.Label>Bike number[e.g. - 201,202,203 (if number of bikes is 3 enter 3 values with ',' as seperator)]</Form.Label>
        <Form.Control type="string" placeholder="Bike number" onChange={e=>setformdata({...formdata,bikenumber:e.target.value})}/>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasic">
        <Form.Label>Next payout<br/><p>[date of next payout ] </p></Form.Label>
        <Form.Control type="date" placeholder="next payout" onChange={e=>setformdata({...formdata,nextpayout:e.target.value})}/>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasic">
        <Form.Label>Tenure<br/><p>[e.g. - 36 (time period in months) ]</p></Form.Label>
        <Form.Control type="number" placeholder="Tenure" onChange={e=>setformdata({...formdata,tenure:e.target.value})}/>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasic">
        <Form.Label>Payout Amount<br/><p>[e.g. - payoutperbike*noofbikes]</p></Form.Label>
        <Form.Control type="number" placeholder="Payout amount" onChange={e=>setformdata({...formdata,payoutamount:e.target.value})}/>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasic">
        <Form.Label>Rate of interest<br/><p>[e.g. - 13]</p></Form.Label>
        <Form.Control type="number" placeholder="Rate of interest" onChange={e=>setformdata({...formdata,rateofinterest:e.target.value})}/>
          </Form.Group>

           <Form.Group className="mb-3" controlId="formBasic">
        <Form.Label>Investment Number<br/><p>[e.g. - 215]</p></Form.Label>
        <Form.Control type="string" placeholder="Investment Number" onChange={e=>setformdata({...formdata,investmentnumber:e.target.value})}/>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasic">
        <Form.Label>Starting date<br/><p>[e.g. - starting date of payout]</p></Form.Label>
        <Form.Control type="date" placeholder="Starting date" onChange={e=>setformdata({...formdata,startingdate:e.target.value})}/>
          </Form.Group>

           <Form.Group className="mb-3" controlId="formBasic">
        <Form.Label>Payout Remaining<br/><p>[e.g. - 32]</p></Form.Label>
        <Form.Control type="string" placeholder="Payout Remaining" onChange={e=>setformdata({...formdata,payoutremaining:e.target.value})}/>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasic">
        <Form.Label>Total number of payouts<br/><p>[e.g. - 36]</p></Form.Label>
        <Form.Control type="string" placeholder="Total number of payouts" onChange={e=>setformdata({...formdata,totalnoofpayouts:e.target.value})}/>
          </Form.Group>
      
               <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>Invoice</Form.Label>
        <Form.Control accept=".pdf" type="file" onChange={e=>setformdata({...formdata,invoice:e.target.files[0]})}/>
          </Form.Group>
               <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>Lease agreement</Form.Label>
        <Form.Control accept=".pdf" type="file" onChange={e=>setformdata({...formdata,leaseagreement:e.target.files[0]})}/>
      </Form.Group>
      <Button variant="primary" type="submit" onSubmit={e=>console.log(e.target.value)}>
        Submit
      </Button>
    </Form>
      </Container>
  );
}

export default Manualinvestment;
