import React,{useState,useEffect} from 'react'
import { Link, Navigate } from 'react-router-dom'
import { Row,Col,Button,Form,Modal,Tab,Tabs,Alert,Toast,ToastContainer,Popover,OverlayTrigger } from 'react-bootstrap'
import useForm from '../modules/formWithSubmit/useForm'
import instance from '../helpers/instance'
import Sidepanel from './Sidepanel'
import {useNavigate} from "react-router-dom"
import Navbarcomp from './Navbarcomp'
import { useSelector, useDispatch } from "react-redux";
import { adddetails } from '../reduxSlices/userSlice'

// const instance = require('../helpers/instance')
function Tasks() {

  	const dispatch = useDispatch();
  
    const [ev, setEv] = useState(false) //ev - email verification status true/false
    const [kyc, setKyc] = useState(0) //ev - kyc verification status 0,1,2
    const [ba, setBa] = useState(0) //ba - bank account verification status 0,1,2
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const navigate = useNavigate();
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    var chkyc;

    const [isMobile, setIsMobile] = useState(3)
    
    const handleResize = () => {
      
      
      if (document.documentElement.clientWidth >= 942) {
        setIsMobile(3)
      }
      else
      {setIsMobile(1)}
    }

        const check_kyc = async() =>{
    // console.log('-------')
          let res = await instance.get('/api/auth/getuser');

          let kycstatus = res.data.user.tasks.kyc.verified;
          let bankstatus = res.data.user.tasks.bankDetails.added;

            setKyc(kycstatus)
            setBa(bankstatus)

            let val = res.data.user.emailVerified
            setEv(val);
            let condition = (kycstatus===2||kycstatus===0||kycstatus===-1) && (bankstatus===2 || bankstatus===0 || bankstatus===-1);
            (condition)
            ?clearInterval(chkyc)
            :console.log(res.data.user.tasks)

            if(kycstatus===2 && bankstatus===2 && val===true)
            navigate('/dashboard')
    
  }
  
	const [popupmsg, setPopupmsg] = useState("");

        	const Toastfunc = () => {
		return (
			<ToastContainer className="p-3" position="middle-center">
				<Toast onClose={() => setShow2(false)} show={show2} delay={5000} autohide>
					<Toast.Header>
						<img
							src="holder.js/20x20?text=%20"
							className="rounded me-2"
							alt=""
						/>
						<strong className="me-auto">Alert</strong>
						{/* <small></small> */}
					</Toast.Header>
					<Toast.Body>{popupmsg} !</Toast.Body>
				</Toast>
			</ToastContainer>
		);
	};

const verificationhandler = (ver) =>{
  console.log(ver)
  setKyc(ver.tasks.kyc.verified);
  setBa(ver.tasks.bankDetails.added)
  setEv(ver.emailVerified)
  // console.log(ver.emailVerified)
  // console.log(ev)
  
}
const getuserfunc = async() =>{
  // instance.get('/api/repute/status').then(res=>setVerified(res.verified))

  const data = await instance.get('/api/auth/getuser');
  console.log(data.data.user)
  dispatch(adddetails(data.data.user));
  
  const kycchk = data.data.user.tasks.kyc.verified;
  const bankaccountcheck = data.data.user.tasks.bankDetails.added;


  if(kycchk===2 && bankaccountcheck===2 && data.data.user.emailVerified===true)
  navigate('/dashboard')
  else if(data.data.user.emailVerified===null || data.data.user.emailVerified===undefined)
  {
    navigate('/')
  }
  (kycchk===1 || bankaccountcheck===1)?chkyc=setInterval(check_kyc, 10000):
  <></>
  const ver = data.data.user;
  verificationhandler(ver);
  // console.log(data)
}


  useEffect(() => {

    handleResize();
    getuserfunc();
  }, []);


  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })
  
     const Check = () => {return <i style={{color:"green",fontSize:"1.5rem "}} className="far fa-check-circle"></i>};
     const Processing = () => {return <i style={{color:"gold",fontSize:"1.5rem "}} className="fas fa-spinner"></i>};
     const Failed = () => {return <i style={{color:"red",fontSize:"1.5rem "}} className="far fa-times-circle"></i>};
    
      const mailhandler = async() =>{
       let res = await instance.get('/api/email/send')  
        setPopupmsg("Email sent ");
        setShow2(true);
      }

 
    

    const kychandler = async() =>{

      const user = await instance.post('/api/repute/create');
      // chkyc=setInterval(check_kyc, 100000)
      if(user)
      {setKyc(1);
        window.open(user.data.url, '_blank');
      getuserfunc()}
        
      }
      const kychandler2 = async() =>{

        const user = await instance.post('/api/repute/existingurl');
        // chkyc=setInterval(check_kyc, 100000)
        if(user)
        {
          window.open(user.data.url, '_blank');
        getuserfunc()}
          
        }

      const bankfunc = async(data) =>{
        // console.log(data);
        
        let res = await instance.post('/api/razorpay/bankvalidation', data);
        if(res.data==='created')
        {  setBa(1);
          getuserfunc();
        }
        setPopupmsg(res.data);
        setShow2(true);
       
      }
      const upifunc = async(data) =>{
        // console.log(data);
        
        let res = await instance.post('/api/razorpay/upivalidation',data);
         if(res.data==='created')
        {  setBa(1);
          getuserfunc();
        }
        console.log(res.data)
        setPopupmsg(res.data);
        setShow2(true);


      }

      const [bankvalues,bankhandleChange,bankhandleSubmit] = useForm(bankfunc)
      const [upivalues,upihandleChange,upihandleSubmit] = useForm(upifunc)
      if(isMobile===3)
  return (
        <div style={{height:"100vh"}}> 

        
      
        <Toastfunc/>
          <Navbarcomp />
				
          <div style={{borderBottom:'1px solid #999999'}}>

          <div className='a3' >
            Tasks to complete
          </div>
          </div>
    <Row style={{margin:"0% 0% 0 0%",borderBottom:"2px solid #999999"}}></Row>

			<Row style={{height:"inherit"}}>
          <Col md={2} style={{borderRight:"1px solid #999999"}}>
              <Sidepanel/>
          </Col>
          <Col md={10}>
     

            <Row style={{margin:"4.5% 0 4.5% 10%" }}>

            <Col  md={4}>
            <div style={{textAlign:"left",fontSize:"1.5rem"}}>Email Verification</div><br/>
            </Col>
            {/* <OverlayTrigger trigger="click" placement="right" overlay={popover}>
    <Button variant="success">Click me to see</Button>
  </OverlayTrigger>
            <OverlayTrigger trigger="click" placement="right" overlay={popover}>
    <Button variant="success">gdf</Button>
  </OverlayTrigger>
             */}

            <Col  md={8}>
              {
                ev===false?
                  <Button  onClick={mailhandler} className='s3' style={{ textDecoration: '', color: 'white' }} to="#">
                    &emsp;&emsp;&ensp;&nbsp;Verify&nbsp;&nbsp;&emsp;&ensp;&emsp;
                  </Button>
                :<Check/>
              }
            </Col>
            </Row>

            <Row style={{margin:"0% 10% 0 10%",borderBottom:"2px solid #999999"}}></Row>


            <Row style={{margin:"4.5% 0 4.5% 10%"}}>

            <Col  md={4}>
              <div style={{ textAlign: "left", fontSize: "1.5rem" }}>Complete KYC <br/>
               <OverlayTrigger
          trigger= {['click','hover','focus']}
          key="kyc"
          placement="top-start"
          overlay={
            <Popover id="kyc">
              <Popover.Header as="h3">Complete KYC</Popover.Header>
              <Popover.Body>
                Upload the KYC details to complete your KYC verification.
              </Popover.Body>
            </Popover>
          }
        >
          <Button variant="none"><i className="fas fa-info"></i></Button>
              </OverlayTrigger>
              </div>
              
                        
              
            
            </Col>
            <Col  md={8}>
              { 
                kyc===0 || kyc===1?
                <div style={{display:"flex",justifyContent:"space-evenly"}}>
                {/* <i style={{color:"gray",fontSize:"1.5rem "}} className="fas fa-spinner"></i> */}
                <Button onClick={e=>{kychandler(e);}}>Upload Documents</Button>
                </div>
                :
                kyc===1?
                <div style={{display:"flex",justifyContent:"space-evenly"}}> 
                  {/* <Processing/> */}
                <Button onClick={e=>{kychandler2(e);}}>Upload Documents</Button>
                  </div>
                :kyc===-1?<Failed/>:
                <Check/>
              }
            </Col>
            </Row>

            <Row style={{margin:"0% 10% 0 10%",borderBottom:"2px solid #999999"}}></Row>

            <Row style={{margin:"4.5% 0 4.5% 10%"}}>

            <Col md={4}>
              <div style={{ textAlign: "left", fontSize: "1.5rem" }}>Add Bank Account<br />
              
               <OverlayTrigger
          trigger= {['click','hover','focus']}
          key="bank"
          placement="top-start"
          overlay={
            <Popover id="bank">
              <Popover.Header as="h3">Add Bank Details</Popover.Header>
              <Popover.Body>
               Upload Bank Details to complete your bank account verification
              {/* <div style={{fontWeight:"600",fontSize:'1.2rem'}}> */}
            Note : Payout will be given to this bank account.
          {/* </div> */}
.
              </Popover.Body>
            </Popover>
          }
        >
          <Button variant="none"><i className="fas fa-info"></i></Button>
              </OverlayTrigger></div>
            </Col>
            <Col md={8}>
            { 
                ba===0?
            <Button onClick={handleShow}>Upload Documents</Button>:
            ba===1?<Processing/>
            :ba===-1?<Failed/>:
            <Check/>
              }

              <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Tabs defaultActiveKey="bank" id="uncontrolled-tab-example" className="mb-3">
                  <Tab eventKey="bank" title="Bank Details">
                {/* <Modal.Title>Bank Account Details</Modal.Title> */}
                <Modal.Body>
                    <Form>
                    <Form.Group className="mb-3" controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                    name="name"
                    type="text"
                    placeholder="Enter Name"
                    value={bankvalues["name"] || ""}
                    onChange={bankhandleChange}
                    />
                    </Form.Group>
                    </Form>
                    <Form.Group className="mb-3" controlId="Acno">
                    <Form.Label>Account Number</Form.Label>
                    <Form.Control
                    name="Acno"
                    type="number"
                    placeholder="Enter Account number"
                    value={bankvalues["Acno"] ||""}
                    onChange={bankhandleChange}
                    />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="IFSC">
                    <Form.Label>IFSC Code</Form.Label>
                    <Form.Control
                    name="IFSC"
                    type="text"
                    placeholder="IFSC Code"
                    value={bankvalues["IFSC"] || ""}
                    onChange={bankhandleChange}
                    />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary"   type="submit" onClick={(e)=>{bankhandleSubmit(e);handleClose(e);}}>
                Submit
                </Button>
                </Modal.Footer>
                    </Tab>
                  <Tab eventKey="upi" title="UPI Details">
                  <Modal.Body>
                    <Form>
                    <Form.Group className="mb-3" controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                    name="name"
                    type="text"
                    placeholder="Enter Name"
                    value={upivalues["name"] || ""}
                    onChange={upihandleChange}
                    />
                    </Form.Group>
                    </Form>
                    <Form.Group className="mb-3" controlId="Acno">
                    <Form.Label>UPI</Form.Label>
                    <Form.Control
                    name="upi"
                    type="text"
                    placeholder="Enter UPI"
                    value={upivalues["upi"] ||""}
                    onChange={upihandleChange}
                    />
                    </Form.Group>
                   
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary"   type="submit" onClick={(e)=>{upihandleSubmit(e);handleClose(e);}}>
                Submit
                </Button>
                </Modal.Footer>
                  </Tab>
                </Tabs>
              </Modal>
            </Col>
            </Row>
            </Col>
            </Row>

    </div>
  )
  else
  return(
    < >
      <Navbarcomp />
    <Row style={{margin:"0% 0% 0 0%",borderBottom:"2px solid #999999"}}></Row>
      
      <Sidepanel/>
    <Row>
    <div style={{textAlign:"left",margin:"2% 0 0 10%",fontSize:"1.4rem",fontWeight:"bold",textDecoration:"underline"}}>Tasks to complete</div>
    </Row>
  
<Row style={{margin:"3% 0 3% 10%"}}>

<Col  md={4}>
<div style={{textAlign:"left",fontSize:"1.5rem"}}>Email verification</div><br/>
</Col>
<Col  style={{margin:"2% 0 2% 0"}} md={8}>
  {
    ev===false?
<Button onClick={mailhandler} className='s3' style={{textDecoration:'none',color:'white'}} to="#">                    &emsp;&emsp;&ensp;&nbsp;Verify&nbsp;&nbsp;&emsp;&ensp;&emsp;
</Button>:
<Check/>
  }

</Col>

</Row>
    <Row style={{margin:"0% 10% 0 10%",borderBottom:"2px solid #999999"}}></Row>

    <Row style={{margin:"3% 0 3% 10%"}}>

    <Col  md={4}>
          <div style={{ textAlign: "left", fontSize: "1.5rem" }}>Complete KYC
            <br />
                         <OverlayTrigger
          trigger= {['click','hover','focus']}
          key="mob_kyc"
          placement="top-start"
          overlay={
            <Popover id="mob_kyc">
              <Popover.Header as="h3">Complete KYC</Popover.Header>
              <Popover.Body>
                Upload the KYC details to complete your KYC verification.
              </Popover.Body>
            </Popover>
          }
        >
          <Button variant="none"><i className="fas fa-info"></i></Button>
              </OverlayTrigger></div>
    
    </Col>
    <Col  style={{margin:"2% 0 2% 0"}} md={8}>
      {
        kyc===0?
        <Button onClick={kychandler}>Upload Documents</Button>:
        kyc===1?<Processing/>:kyc===-1?
        <Failed/>
        :<Check/>
      }
    </Col>
    </Row>

    <Row style={{margin:"0% 10% 0 10%",borderBottom:"2px solid #999999"}}></Row>

    <Row style={{margin:"5% 0 5% 10%"}}>

    <Col md={4}>
          <div style={{ textAlign: "left", fontSize: "1.5rem" }}>Add Bank Account<br />
                         <OverlayTrigger
          trigger= {['click','hover','focus']}
          key="mobbank"
          placement="top-start"
          overlay={
            <Popover id="mobbank">
              <Popover.Header as="h3">Add Bank Details</Popover.Header>
              <Popover.Body>
          Upload Bank details to complete your bank account verification
          <div style={{ fontWeight: "600", fontSize: ".4rem" }}>
            Note : Payout will be given to this bank account.
          </div>
              </Popover.Body>
            </Popover>
          }
        >
          <Button variant="none"><i className="fas fa-info"></i></Button>
            </OverlayTrigger>
          </div>
          
    </Col>
    <Col md={8} style={{margin:"2% 0 2% 0"}}>
              {
                ba===0?
            <Button onClick={handleShow}>Upload Documents</Button>:
            ba===1?<Processing/>
            :ba===-1?<Failed/>
            :<Check/>
              }
              <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Tabs defaultActiveKey="bank" id="uncontrolled-tab-example" className="mb-3">
                  <Tab eventKey="bank" title="Bank Details">
                {/* <Modal.Title>Bank Account Details</Modal.Title> */}
                <Modal.Body>
                    <Form>
                    <Form.Group className="mb-3" controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                    name="name"
                    type="text"
                    placeholder="Enter Name"
                    value={bankvalues["name"] || ""}
                    onChange={bankhandleChange}
                    />
                    </Form.Group>
                    </Form>
                    <Form.Group className="mb-3" controlId="Acno">
                    <Form.Label>Account Number</Form.Label>
                    <Form.Control
                    name="Acno"
                    type="number"
                    placeholder="Enter Account number"
                    value={bankvalues["Acno"] ||""}
                    onChange={bankhandleChange}
                    />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="IFSC">
                    <Form.Label>IFSC Code</Form.Label>
                    <Form.Control
                    name="IFSC"
                    type="text"
                    placeholder="IFSC Code"
                    value={bankvalues["IFSC"] || ""}
                    onChange={bankhandleChange}
                    />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary"   type="submit" onClick={(e)=>{bankhandleSubmit(e);handleClose(e);}}>
                Submit
                </Button>
                </Modal.Footer>
                    </Tab>
                  <Tab eventKey="upi" title="UPI Details">
                  <Modal.Body>
                    <Form>
                    <Form.Group className="mb-3" controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                    name="name"
                    type="text"
                    placeholder="Enter Name"
                    value={upivalues["name"] || ""}
                    onChange={upihandleChange}
                    />
                    </Form.Group>
                    </Form>
                    <Form.Group className="mb-3" controlId="Acno">
                    <Form.Label>UPI</Form.Label>
                    <Form.Control
                    name="upi"
                    type="text"
                    placeholder="Enter UPI"
                    value={upivalues["upi"] ||""}
                    onChange={upihandleChange}
                    />
                    </Form.Group>
                   
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary"   type="submit" onClick={(e)=>{upihandleSubmit(e);handleClose(e);}}>
                Submit
                </Button>
                </Modal.Footer>
                  </Tab>
                </Tabs>
              </Modal>
    </Col>
    </Row>
</>
  )
}

export default Tasks