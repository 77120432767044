import { createSlice } from '@reduxjs/toolkit'

export const forgetpasswordSlice = createSlice({
  name: 'user',
  initialState: {
    value : {
        email: "",
        attempts:0,
    },
  },
  reducers: {
    // increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
    //   state.value += 1
    // },
    // decrement: (state) => {
    //   state.value -= 1
    // },
    
    emailver: (state, action) => {
      state.value.email = action.payload.emailreq
      state.value.attempts = 3;

    },
    changeattempt:(state)=>{
        state.value.attempts = state.value.attempts-1;
    }
  },
})

// Action creators are generated for each case reducer function
export const { changeattempt } = forgetpasswordSlice.actions
export const { emailver } = forgetpasswordSlice.actions

export default forgetpasswordSlice.reducer