import {useState} from "react";

function useFilter(defaultFilter){
    var [filter,setFilter]=useState(defaultFilter || {});
    var [values,setValues]=useState({});
    function handleFilterSubmit(e) {
        if(e){
            e.preventDefault();
        }
        var temp = {...values};
        setFilter(temp);
    };
    function handleFilterChange(e){
        var temp={...values};
        // console.log(e)
        temp[e.target.name] = e.target.value;
        setValues(temp);
    }
    return {filter, handleFilterSubmit, handleFilterChange,setFilter};
}
export default useFilter;