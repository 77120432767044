import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    value : {
        name: "",
        email: "",
        city: "",
        countryCode: "",
        state:"",
        mobileNumber: "",
        bankdetailsverified:0,
        kycverified:0,
        emailVerified:false,
    },
  },
  reducers: {
    // increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
    //   state.value += 1
    // },
    // decrement: (state) => {
    //   state.value -= 1
    // },
    adddetails: (state, action) => {
      state.value.name = action.payload.name
      state.value.email = action.payload.email
      state.value.city = action.payload.city
      state.value.countryCode = action.payload.countryCode
      state.value.state = action.payload.state
      state.value.mobileNumber = action.payload.mobileNumber
      state.value.kycverified = action.payload.tasks.kyc.verified
      state.value.bankdetailsverified = action.payload.tasks.bankDetails.added
      state.value.emailVerified = action.payload.emailVerified

    },

  },
})

// Action creators are generated for each case reducer function
export const { adddetails } = userSlice.actions

export default userSlice.reducer