import React, { useState, useEffect, useReducer } from "react";
import validator from "validator";
import GoogleFormHandler from "../modules/googleFormHandler/GoogleFormHandler";
import useForm from "../modules/formWithSubmit/useForm";
import { Alert, Row, Form, Button, Tabs, Tab, Col } from "react-bootstrap";
import instance from "../helpers/instance.js";
import {
	getCountries,
	getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";
import "../sass/edit.scss";
import { useSelector, useDispatch } from "react-redux";
import Sidepanel from "./Sidepanel";
import Navbarcomp from "./Navbarcomp";

const initialState = { errMsg: "" };

function reducer(state, action) {
	switch (action.type) {
		case "add":
			return { errMsg: state.payload.errMsg };
		// case 'decrement':
		//   return {count: state.count - 1};
		default:
			throw new Error();
	}
}

function Edit() {
	const [state, dispatch] = useReducer(reducer, initialState);

	const [show, setShow] = useState(true);
	const [acno, setAcno] = useState(true);
	const [upi, setUpi] = useState(true);
	const [ifsc, setIfsc] = useState(true);
	const [state1, setState1] = useState(true);
	const [city, setCity] = useState(true);
	const [country, setCountry] = useState("IN");
	const [formdata, setformdata] = useState("");
	const [password, setPassword] = useState(true);
	const [isvalid, setIsvalid] = useState(true);
	const [ismatch, setIsmatch] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const data = useSelector(state => state.user.value);
	const emailvalidation = value => {
		if (validator.isEmail(value)) console.log("email");
		else console.log("not an email");
	};

	const validate = e => {
		if (
			validator.isStrongPassword(e, {
				minLength: 8,
				minLowercase: 1,
				minUppercase: 1,
				minNumbers: 1,
				minSymbols: 1,
			})
		) {
			setErrorMessage("Is Strong Password");
			setIsvalid(true);
		} else {
			let ermsg = "";
			console.log(e);

			let password = e;
			console.log(password);

			let strengthValue = {
				caps: false,
				length: false,
				special: false,
				numbers: false,
				small: false,
			};
			let strengthIndicator = 0;
			if (password.length >= 8) {
				strengthValue.length = true;
				console.log(password.length);
			}

			for (let index = 0; index < password.length; index++) {
				let char = password.charCodeAt(index);
				if (!strengthValue.caps && char >= 65 && char <= 90) {
					strengthValue.caps = true;
				} else if (!strengthValue.numbers && char >= 48 && char <= 57) {
					strengthValue.numbers = true;
				} else if (!strengthValue.small && char >= 97 && char <= 122) {
					strengthValue.small = true;
				} else if (!strengthValue.numbers && char >= 48 && char <= 57) {
					strengthValue.numbers = true;
				} else if (
					(!strengthValue.special && char >= 33 && char <= 47) ||
					(char >= 58 && char <= 64)
				) {
					strengthValue.special = true;
				}
			}
			for (let metric in strengthValue) {
				if (strengthValue[metric] === false) {
					if (metric !== "length") {
						strengthIndicator++;
						ermsg += `\n 1 ${metric}   character ,`;
					}
				}
			}

			if (strengthIndicator > 0)
				ermsg = "Password must contain atleast " + ermsg;

			if (strengthValue["length"] === false)
				ermsg += `\nLength should be greater than or equal to 8 .`;

			if (strengthIndicator === 0) setErrorMessage("Strong Password");
			console.log("ermsg", ermsg);
			setErrorMessage(ermsg);
			console.log("finished");

			setIsvalid(false);
			// setErrorMessage('Is Not Strong Password')
		}
	};

	const [isMobile, setIsMobile] = useState(3);
	//choose the screen size
	const handleResize = () => {
		if (document.documentElement.clientWidth >= 1296) {
			setIsMobile(3);
		} else if (document.documentElement.clientWidth >= 800) {
			setIsMobile(2);
		} else {
			setIsMobile(1);
		}
	};

	// create an event listener
	useEffect(() => {
		window.addEventListener("resize", handleResize);
	});

	useEffect(() => {
		handleResize();
	}, []);

	const func = value => {
		console.log("response", value);
		if (value.Acno !== undefined && value.IFSC !== undefined && upi === false) {
			delete value.upi;
			instance
				.post("/api/razorpay/updatebank", value)
				.then(res => console.log(res));
		} else {
			delete value.Acno;
			delete value.IFSC;
			if (value.upi !== null && value.upi !== undefined)
				instance
					.post("/api/razorpay/updateupi", value)
					.then(res => console.log(res));
		}
	};
	const [values, handleChange, handleSubmit] = useForm(func);

	const [caller, setCaller] = useState(true);
	useEffect(() => {
		setErrorMessage("");
	}, [caller]);

	if (isMobile === 3) {
		return (
			<div style={{height:"100vh"}}>
    			{/* <Row style={{margin:"0% 0% 0 0%",borderBottom:"2px solid #999999"}}></Row> */}
				
				<Row style={{height:'inherit'}}>
					<Col md={2} style={{ borderRight: "1px solid #999999"}}>
						<div style={{ display:"block",marginTop:"7%",marginLeft:"auto",marginRight:"auto",width:"62%"}}>
							
						<Navbarcomp />
					</div>
							<Sidepanel />
						</Col>
						<Col className="a1" md={8}>
							<div className="a2">Edit Profile</div>

						<Form style={{marginTop:"12%"}}>
								<Form.Group as={Row} className="mb-3" controlId="mob">
									<Form.Label column sm={4}>
										Name
									</Form.Label>
								<Col sm={6} >
										<Form.Control
											name="name"
											type="text"
											placeholder={data.name}
											value={data.name}
											// onChange={handleChange}
											disabled={true}
										/>
									</Col>
								</Form.Group>
								<Form.Group as={Row} className="mb-3" controlId="mob">
									<Form.Label column sm={4}>
										Email
								</Form.Label>
									<Col sm={6} >
										<Form.Control
											name="email"
											type="text"
											placeholder={data.email}
											value={data.email}
											// onChange={handleChange}
											disabled={true}
										/>
									</Col>

								</Form.Group>
								<Form.Group as={Row} className="mb-3" controlId="mob">
									<Form.Label column sm={4}>
										Mobile number
								</Form.Label>
								<Col sm={6} >
										<Form.Control
											name="mobilenumber"
											type="text"
											placeholder={data.mobileNumber}
											value={data.mobileNumber}
											// onChange={handleChange}
											disabled={true}
										/>
									</Col>

								</Form.Group>
							

								<Form.Group as={Row} className="mb-3" controlId="Acno">
									<Form.Label column sm={4}>
										Account Number
									</Form.Label>
									<Col sm={6}>
										<Form.Control
											name="Acno"
											type="text"
											placeholder="Enter Account number"
											value={values["Acno"] || ""}
											onChange={handleChange}
											disabled={upi}
										/>
									</Col>
									<Col sm={2}>
										<Button onClick={e => setUpi(!upi)}>Edit</Button>
									</Col>
								</Form.Group>
								<Form.Group as={Row} className="mb-3" controlId="IFSC">
									<Form.Label column sm={4}>
										IFSC Code
									</Form.Label>
									<Col sm={6}>
										<Form.Control
											name="IFSC"
											type="text"
											placeholder="IFSC Code"
											value={values["IFSC"] || ""}
											onChange={handleChange}
											disabled={upi}
										/>
									</Col>
									<Col sm={2}>
										<Button onClick={e => setUpi(!upi)}>Edit</Button>
									</Col>
								</Form.Group>
								<Form.Group as={Row} className="mb-3" controlId="upi">
									<Form.Label column sm={4}>
										UPI
									</Form.Label>
									<Col sm={6}>
										<Form.Control
											name="upi"
											type="text"
											placeholder="UPI Id"
											value={values["upi"] || ""}
											onChange={handleChange}
											disabled={!upi}
										/>
									</Col>
									<Col sm={2}>
										<Button onClick={e => setUpi(!upi)}>Edit</Button>
									</Col>
								</Form.Group>
								<Button
									variant="primary"
									type="submit"
									onClick={e => {
										handleSubmit(e);
									}}
								>
									Submit
								</Button>
							</Form>
							{/* <GoogleFormHandler id="contact-iframe" title="Form Submitted">
              Your Query has been Submitted. You can now go to the home page.
              </GoogleFormHandler> */}
						</Col>
					</Row>
			</div>
		);
	} else {
		return (
			<div>
				<>
						<div style={{ display:"block",marginTop:"7%",marginLeft:"auto",marginRight:"auto",width:"45%"}}>

						<Navbarcomp/>
					</div>

					<Row>
						<div style={{textAlign:"right"}}>

							<Sidepanel />
						</div>
						
						
							<div className="a2">Edit Profile</div>

							<Form
							//   action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSckuT7S7_mCj5QDPXAAkM_QfwuNmuyn41GhTlYfPJ40JVd8sg/formResponse"
							//   method="POST"
							//   target="contact-iframe"
							>
								{/* onClick={() => dispatch({type: 'reset', payload: initialCount})}> */}

								<Form.Group as={Row} controlId="mob">
									<Form.Label column xs={3}>
										Name
									</Form.Label>
									<Col xs={6}>
										<Form.Control
											name="name"
											type="text"
											value={data.name}
											disabled={true}
										/>
									</Col>
								</Form.Group>
							<Form.Group as={Row} controlId="mob" style={{ marginTop: "2%" }}>
									<Form.Label column xs={3}>
										Email
									</Form.Label>
									<Col xs={6}>
										<Form.Control
											name="email"
											type="text"
											value={data.email}
											disabled={true}
										/>
									</Col>
								</Form.Group>
								<Form.Group as={Row} className="mb-3" controlId="mob" style={{ marginTop: "2%" }}>
									<Form.Label column xs={3}>
										Mobile number
									</Form.Label>
									<Col xs={6}>
										<Form.Control
											name="mobilenumber"
											type="text"
											placeholder="Enter Mobile number"
											value={data.mobileNumber}
											disabled={true}
										/>
									</Col>
								</Form.Group>
								{/* <Form.Group as={Row} className="mb-3" controlId="password1">
                  <Form.Label column xs={4}>Password</Form.Label>
                  <Col xs={6}>
                      <Form.Control
                      name="password1"
                      type="password"
                      placeholder="Password"
                      //   value={values["password"]}
                      disabled={password}
                      onChange={(e)=>{
                        validate(e.target.value);
                        setformdata(e.target.value);
                        // setCaller(!caller);
                        // aalert(e);
      
                      }}
                      />
                  </Col>
                  <Col xs={2}><Button onClick={e=>setPassword(!password)}>Edit</Button></Col>
                  {isvalid?<div style={{color:"green",textWeight:"bold"}}>{errorMessage}</div>:<span style={{color:"red",textWeight:"bold"}}>{errorMessage}</span>}
              
              </Form.Group>
              {!password?<>
                <Form.Group as={Row}>
              <Form.Label column xs={4}>Confirm Password</Form.Label>
              <Col xs={8}>
      
                  <Form.Control
                  name="password"
                  type="password"
                  placeholder="password"
                  // value={signupValues["password"] || ""}
                  onChange={e=>{e.target.value===formdata?(setIsmatch(true)):(setIsmatch(false));
                    //   ismatch&&isvalid?handleChange(e):handleChange(null)
                    handleChange(e);
                  }
                }
                disabled={password}
                />
              </Col>
                </Form.Group>
      
              </>:null}
                  {ismatch?<span style={{color:"green",textWeight:"bold"}}>Matched</span>:<span style={{color:"red",textWeight:"bold"}}>Not match</span>} */}
								{/* <Form.Group as={Row} controlId="city">
                  <Form.Label column xs={4}>City</Form.Label>
                  <Col xs={8}>
                  <Form.Control
                  name="city"
                  type="text"
                  placeholder={data.city}
                  // value={signupValues["city"] || ""}
                  // onChange={handleChange}
                  disabled={city}
                  />
                  </Col>
                  <Col sm={2}><Button onClick={e=>setCity(!city)}>Edit</Button></Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="city">
                  <Form.Label column={4}>State</Form.Label>
                  <Col sm={6}>
                  <Form.Control
                  name="state"
                  type="text"
                  placeholder={data.state}
                  // value={signupValues["state"] || ""}
                  onChange={handleChange}
                  disabled={state}
                  />
                  </Col>
                  <Col sm={2}><Button onClick={e=>setState1(!state1)}>Edit</Button></Col>
      
              </Form.Group>
               */}

								{/* <Form.Group  className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="Check me out" />
              </Form.Group> */}

								<Form.Group as={Row} className="mb-3" controlId="Acno">
									<Form.Label column xs={3}>
										Account Number
									</Form.Label>
									<Col xs={6}>
										<Form.Control
											name="Acno"
											type="text"
											placeholder="Enter Account number"
											value={values["Acno"] || ""}
											onChange={handleChange}
											disabled={upi}
										/>
									</Col>
									<Col xs={2}>
										<Button onClick={e => setUpi(!upi)}>Edit</Button>

									</Col>
								</Form.Group>
								<Form.Group as={Row} className="mb-3" controlId="IFSC">
									<Form.Label column xs={3}>
										IFSC Code
									</Form.Label>
									<Col xs={6}>
										<Form.Control
											name="IFSC"
											type="text"
											placeholder="IFSC Code"
											value={values["IFSC"] || ""}
											onChange={handleChange}
											disabled={upi}
										/>
									</Col>
									<Col xs={2} style={{ marginTop: "2%" }}>
										<Button onClick={e => setUpi(!upi)}>Edit</Button>
									</Col>
								</Form.Group>
								<Form.Group as={Row} className="mb-3" controlId="upi">
									<Form.Label column xs={3}>
										UPI
									</Form.Label>
									<Col xs={6}>
										<Form.Control
											name="upi"
											type="text"
											placeholder="UPI Id"
											value={values["upi"] || ""}
											onChange={handleChange}
											disabled={!upi}
										/>
									</Col>
									<Col xs={2} style={{ marginTop: "2%" }}>
										<Button onClick={e => setUpi(!upi)}>Edit</Button>
									</Col>
								</Form.Group>
								<Button
									variant="primary"
									type="submit"
									onClick={e => {
										handleSubmit(e);
									}}
								>
									Submit
								</Button>
							</Form>
							{/* <GoogleFormHandler id="contact-iframe" title="Form Submitted">
              Your Query has been Submitted. You can now go to the home page.
              </GoogleFormHandler> */}
					
					</Row>
				</>
			</div>
		);
	}
}

export default Edit;
