import React from "react";
import { Container, Navbar } from 'react-bootstrap'
import logo from './../assets/logo/edrives_blk.png'
function Navbarcomp() {
    return <div>
       <Navbar bg="">
        <Container>
          <Navbar.Brand href="#home" >
            <img
              src={logo}
              width="100"
              height="118"
              className="d-inline-block "
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
        </Container>
      </Navbar>
  </div>;
}

export default Navbarcomp;
