import {configureStore} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'
import userReducer from './reduxSlices/userSlice'
import forgetpassword from './reduxSlices/forgetpasswordSlice';
import {combineReducers} from "redux"; 
import { persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'

const reducers = combineReducers({
  user : userReducer,
  confirmpassword : forgetpassword
 });

const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
})