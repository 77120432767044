import React, { useState, useEffect } from "react";
import Navbarcomp from './Navbarcomp'
import './../sass/login.scss'
import useForm from "../modules/formWithSubmit/useForm";
import {
	Container,
	Row,
	Form,
	Button,
	Tabs,
	Tab,
	Col,
	Toast,
	ToastContainer,
	Modal,
} from "react-bootstrap";
import instance from "../helpers/instance.js";
import GoogleFormHandler from "../modules/googleFormHandler/GoogleFormHandler";
import validator from "validator";
// import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import {
	getCountries,
	getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";
import { useSelector, useDispatch } from "react-redux";
import { adddetails } from "../reduxSlices/userSlice";
import { emailver } from "../reduxSlices/forgetpasswordSlice";
import { useNavigate,Link } from "react-router-dom";
import isEmail from "validator/lib/isEmail";

function Login() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [show, setShow] = useState(false);
	const [show2, setShow2] = useState(false);

	const handleClose2 = () => setShow2(false);
	const handleShow2 = () => setShow2(true);
	const [resetpassword, setResetpassword] = useState(0);

	// const {values,handleChange,handleSubmit} = useForm(func)
	const [country, setCountry] = useState("IN");
	const [formdata, setformdata] = useState("");

	const [errorMessage, setErrorMessage] = useState("");
	const [isvalid, setIsvalid] = useState(false);
	const [ismatch, setIsmatch] = useState(false);
	const [Ev, setEv] = useState(""); //Ev storing email for verification
	const [Pv, setPv] = useState(""); //Pv storing password for verification

	const [isMobile, setIsMobile] = useState(3);
	//choose the screen size
	const handleResize = () => {
		if (document.documentElement.clientWidth >= 1296) {
			setIsMobile(3);
		} else if (document.documentElement.clientWidth >= 800) {
			setIsMobile(2);
		} else {
			setIsMobile(1);
		}
	};

	// create an event listener
	useEffect(() => {
		window.addEventListener("resize", handleResize);
	});

	const fetchData = async () => {
		const data = await instance.get("/api/auth/getuser");
		// console.log(data)
		if (data) {
			const user = data.data.user.tasks;
			let x = user.bankDetails.added;
			let y = user.kyc.verified;

			if (x == 2 && y == 2 && data.data.user.emailVerified === true)
				navigate("/dashboard");
			else navigate("/tasks");
		} else {
			navigate("/");
		}
	};
	useEffect(() => {
		handleResize();
		fetchData();
	}, []);

	const [popupmsg, setPopupmsg] = useState("");

	const Toastfunc = () => {
		return (
			<ToastContainer className="p-3" position="middle-center">
				<Toast onClose={() => setShow(false)} show={show} delay={5000} autohide>
					<Toast.Header>
						<img
							src="holder.js/20x20?text=%20"
							className="rounded me-2"
							alt=""
						/>
						<strong className="me-auto">Alert</strong>
						{/* <small></small> */}
					</Toast.Header>
					<Toast.Body>{popupmsg} !</Toast.Body>
				</Toast>
			</ToastContainer>
		);
	};

	const emailvalidation = value => {
		if (validator.isEmail(value)) console.log("email");
		else console.log("not an email");
	};

	// const val1 = (val) =>{
	//   if(validator.isMobilePhone(val))
	//   console.log('correct')
	//   else console.log('incorrect')
	// }
	const validate = e => {
		if (
			validator.isStrongPassword(e, {
				minLength: 8,
				minLowercase: 1,
				minUppercase: 1,
				minNumbers: 1,
				minSymbols: 1,
			})
		) {
			setErrorMessage("Is Strong Password");
			setIsvalid(true);
		} else {
			let ermsg = "";
			// console.log(e)

			let password = e;
			//  console.log(password)

			let strengthValue = {
				caps: false,
				length: false,
				special: false,
				numbers: false,
				small: false,
			};
			let strengthIndicator = 0;
			if (password.length >= 8) {
				strengthValue.length = true;
				//  console.log(password.length)
			}

			for (let index = 0; index < password.length; index++) {
				let char = password.charCodeAt(index);
				if (!strengthValue.caps && char >= 65 && char <= 90) {
					strengthValue.caps = true;
				} else if (!strengthValue.numbers && char >= 48 && char <= 57) {
					strengthValue.numbers = true;
				} else if (!strengthValue.small && char >= 97 && char <= 122) {
					strengthValue.small = true;
				} else if (!strengthValue.numbers && char >= 48 && char <= 57) {
					strengthValue.numbers = true;
				} else if (
					(!strengthValue.special && char >= 33 && char <= 47) ||
					(char >= 58 && char <= 64)
				) {
					strengthValue.special = true;
				}
			}
			for (let metric in strengthValue) {
				if (strengthValue[metric] === false) {
					if (metric !== "length") {
						strengthIndicator++;
						ermsg += `\n 1 ${metric}   character ,`;
					}
				}
			}

			if (strengthIndicator > 0)
				ermsg = "Password must contain atleast " + ermsg;

			if (strengthValue["length"] === false)
				ermsg += `\nLength should be greater than or equal to 8 .`;

			if (strengthIndicator === 0) setErrorMessage("Strong Password");
			//  console.log("ermsg",ermsg)
			setErrorMessage(ermsg);
			//  console.log("finished")

			setIsvalid(false);
			// setErrorMessage('Is Not Strong Password')
		}
	};

	const func1 = data => {
		if (data.ccode !== undefined)
			data.ccode = getCountryCallingCode(data.ccode);
		// console.log(data);

		instance.post("/api/auth/login", data).then(function (response) {
			// console.log(response)
			if (response.data.auth === true) {
				let details = response.data.user;
				dispatch(adddetails(details));
				if (
					details.tasks.kyc.verified === 2 &&
					details.tasks.bankDetails.added === 2
				)
					navigate("/Dashboard");
				else navigate("/tasks");
			}else{
				let msg = response.data.status;
				setPopupmsg(msg);
				setShow(true);
			}
			if (response.data.error !== undefined) {
				let msg = response.data.error;
				setPopupmsg(msg);
				setShow(true);
			}
		});
	};

	const func2 = data => {
		if (data.ccode !== undefined)
			data.ccode = getCountryCallingCode(data.ccode);
		// console.log(data);

		instance.post("/api/auth/signup", data).then(function (response) {
			// console.log(response)
			if (response.data.status === true) navigate("/tasks");
			if (response.data.error !== undefined) {
				let msg = response.data.error;
				setPopupmsg(msg);
				setShow(true);
			}
		});
	};
	const func3 = async data => {
		console.log(data);
		if (isEmail(data.emailreq)) {
			console.log("sent");

			await instance.post("/api/email/resetpassword", data);
			dispatch(emailver(data));
			setResetpassword(3);
			navigate("/forgetpassword");
		}
	};

	const [loginValues, loginHandleChange, loginHandleSubmit] = useForm(func1);
	const [resetValues, resetHandleChange, resetHandleSubmit] = useForm(func3);
	const [signupValues, signupHandleChange, signupHandleSubmit] = useForm(func2);

	useEffect(() => {
		//  console.log(getCountryCallingCode(country))
	}, [country]);

	if (isMobile === 3) {
		return (
			<>
				<Toastfunc />
				<Navbarcomp />
				<div className="greeting" style={{fontSize:"4.6rem"}}>
				Welcome to E Drives !
				</div>
				<div className="greeting" style={{fontSize:"1.6rem",color:"#009444"}}>
					Buy & Lease Program
					</div>

				<Row style={{ margin: "3% 20% 3% 20%", textAlign: "left" }}>
					<Tabs
						defaultActiveKey="Login"
						id="uncontrolled-tab-example"
						className="mb-3"
					>
						<Tab eventKey="Login" title="Login">
							<Form>
								<Form.Group className="mb-3" controlId="emailver">
									<Form.Label>Email</Form.Label>
									<Form.Control
										name="email"
										type="text"
										placeholder="Enter Email"
										// value={[loginValues["emailver"]] || ""}

										onChange={e => {
											setEv(e.target.value);
											emailvalidation(e.target.value);
											loginHandleChange(e);
										}}
									/>
								</Form.Group>
								<Form.Group className="mb-3" controlId="passwordver">
									<Form.Label>Password</Form.Label>
									<Form.Control
										name="password"
										type="password"
										placeholder="Enter Password"
										//   value="passwordver"

										// value={[loginValues["passwordver"]]||""}
										onChange={e => {
											setPv(e.target.value);
											loginHandleChange(e);
										}}
									/>
								</Form.Group>
								<div style={{display:"flex",justifyContent:"space-between"}}>
									<div>
								 <span onClick={e => {
									 loginHandleSubmit(e);
									}} class="button">Login</span>
									</div>
									<div>


								 <Button style={{all:"unset"}}   onClick={handleShow2}>
									Forgot Password ?</Button>
									</div>
								</div>
								
{/* 
								<Button
									variant="primary"
									type="submit"
									onClick={e => {
										loginHandleSubmit(e);
									}}
								>
									Login
								</Button>
								<Button style={{ marginLeft: "1%" }} onClick={handleShow2}>
									Forgot Password
								</Button> */}

								<Form>
									<Modal show={show2} onHide={handleClose2}>
										<Modal.Header closeButton2>
											<Modal.Title>Reset Password</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<Form.Group className="mb-3" controlId="emailreq">
												<Form.Label>Email</Form.Label>
												<Form.Control
													name="emailreq"
													type="text"
													placeholder="Enter Email"
													// value={[loginValues["emailver"]] || ""}

													onChange={e => {
														resetHandleChange(e);
													}}
												/>
											</Form.Group>
										</Modal.Body>
										<Modal.Footer>
											<Button variant="secondary" onClick={handleClose2}>
												Close
											</Button>
											<Button
												variant="primary"
												onClick={e => {
													resetHandleSubmit(e);
													handleClose2(e);
												}}
											>
												Confirm
											</Button>
										</Modal.Footer>
									</Modal>
								</Form>
							</Form>
						</Tab>
						<Tab eventKey="signup" title="Signup">
							<Form
							//   action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSckuT7S7_mCj5QDPXAAkM_QfwuNmuyn41GhTlYfPJ40JVd8sg/formResponse"
							//   method="POST"
							//   target="contact-iframe"
							>
								<Form.Group className="mb-3" controlId="name">
									<Form.Label>Name</Form.Label>
									<Form.Control
										name="name"
										type="text"
										placeholder="Enter Name"
										// value={signupValues["name"] || ""}
										onChange={signupHandleChange}
									/>
								</Form.Group>
								<Form.Group className="mb-3" controlId="formBasicEmail">
									<Form.Label>Email address</Form.Label>
									<Form.Control
										name="email"
										type="email"
										placeholder="Enter email"
										value={signupValues["email"] || ""}
										onChange={e => {
											signupHandleChange(e);
											emailvalidation(e.target.value);
										}}
									/>

									<Form.Text className="text-muted">
										We'll never share your email with anyone else.
									</Form.Text>
								</Form.Group>
								<Form.Group className="mb-3" controlId="password1">
									<Form.Label>Password</Form.Label>
									<Form.Control
										name="password1"
										type="password"
										placeholder="Password"
										//   value={values["password"]}

										onChange={e => {
											validate(e.target.value);
											setformdata(e.target.value);
										}}
									/>
									{isvalid ? (
										<span style={{ color: "green", textWeight: "bold" }}>
											{errorMessage}
										</span>
									) : (
										<span style={{ color: "red", textWeight: "bold" }}>
											{errorMessage}
										</span>
									)}
								</Form.Group>
								<Form.Group>
									<Form.Label>Password</Form.Label>
									<Form.Control
										name="password"
										type="password"
										placeholder="Confirm Password"
										// value={signupValues["password"] || ""}
										onChange={e => {
											e.target.value === formdata
												? setIsmatch(true)
												: setIsmatch(false);
											//   ismatch&&isvalid?handleChange(e):handleChange(null)
											signupHandleChange(e);
										}}
									/>
								</Form.Group>
								{ismatch ? (
									<span style={{ color: "green", textWeight: "bold" }}>
										Matched
									</span>
								) : (
									<span style={{ color: "red", textWeight: "bold" }}>
										Not match
									</span>
								)}
								<Form.Group className="mb-3" controlId="contact">
									{/* <CountryDropdown style={{margin:"1% 0 1% 0"}} 
              labelType="short"
              valueType="short"
          value={country}
          onChange={(val) => console.log(setCountry(val))} /> */}

									{/* <PhoneInput
  international
  defaultCountry="IN"
  value={value}
  onChange={setValue}/> */}
									<Form.Label>Contact Number</Form.Label>
									<Row>
										<Col lg="4">
											<Form.Select
												name="ccode"
												// value={signupValues["ccode"] || ""}
												onChange={event => {
													setCountry(event.target.value || "IN");
													signupHandleChange(event);
												}}
											>
												<option value="">{"Country code"}</option>
												{getCountries().map(country => (
													<option key={country} value={country}>
														{en[country]} +{getCountryCallingCode(country)}
													</option>
												))}
											</Form.Select>
										</Col>
										<Col lg="8">
											<Form.Control
												name="mob"
												type="text"
												placeholder="Phone Number"
												// value={signupValues["mob"] || ""}
												onChange={signupHandleChange}
											/>
										</Col>
									</Row>
								</Form.Group>
								<Form.Group className="mb-3" controlId="city">
									<Form.Label>City</Form.Label>
									<Form.Control
										name="city"
										type="text"
										placeholder="City"
										// value={signupValues["city"] || ""}
										onChange={signupHandleChange}
									/>
								</Form.Group>
								<Form.Group className="mb-3" controlId="city">
									<Form.Label>State</Form.Label>
									<Form.Control
										name="state"
										type="text"
										placeholder="State"
										// value={signupValues["state"] || ""}
										onChange={signupHandleChange}
									/>
								</Form.Group>

								{/* <Form.Group  className="mb-3" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Check me out" />
        </Form.Group> */}
								

			 						<span onClick={e => {
									 signupHandleSubmit(e);
									}} class="button">Submit</span>

								
							</Form>
							{/* <GoogleFormHandler id="contact-iframe" title="Form Submitted">
								Your Query has been Submitted. You can now go to the home page.
							</GoogleFormHandler> */}
						</Tab>
					</Tabs>
				</Row>
			</>
		);
	} else {
		return (
			<>
				<Toastfunc />
					<div style={{ display:"block",marginTop:"7%",marginLeft:"auto",marginRight:"auto",width:"47%"}}>

						<Navbarcomp/>
					</div>
				<div className="greeting" style={{fontSize:"2.5rem"}}>
				Welcome to E Drives !
				</div>
				<div className="greeting" style={{fontSize:"1.2rem",color:"#009444"}}>
					Buy & Lease Program
					</div>
				<Row style={{ margin: "25% 20% 30% 20%", textAlign: "left" }}>
					<Tabs
						defaultActiveKey="Login"
						id="uncontrolled-tab-example"
						className="mb-3"
					>
						<Tab eventKey="Login" title="Login">
							<Form>
								<Form.Group className="mb-3" controlId="emailver">
									<Form.Label>Email</Form.Label>
									<Form.Control
										name="email"
										type="text"
										placeholder="Enter Email"
										// value={[loginValues["emailver"]] || ""}

										onChange={e => {
											setEv(e.target.value);
											emailvalidation(e.target.value);
											loginHandleChange(e);
										}}
									/>
								</Form.Group>
								<Form.Group className="mb-3" controlId="passwordver">
									<Form.Label>Password</Form.Label>
									<Form.Control
										name="password"
										type="password"
										placeholder="Enter Password"
										//   value="passwordver"

										// value={[loginValues["passwordver"]]||""}
										onChange={e => {
											setPv(e.target.value);
											loginHandleChange(e);
										}}
									/>
								</Form.Group>

								<div style={{textAlign:"center"}}>
									<div>
								 <button onClick={e => {
									 loginHandleSubmit(e);
									}} class="button">Login</button>
									</div>
									<div>


								 <Button style={{all:"unset"}}   onClick={handleShow2}>
									Forgot Password ?</Button>
									</div>
								</div>
								{/* <Row>
									<span onClick={e => {
									 loginHandleSubmit(e);
									}} class="button">Login</span>
								</Row>
								<Row>
									<Button style={{all:"unset"}}   onClick={handleShow2}>
									Forgot Password ?</Button>
								</Row> */}

								<Form>
									<Modal show={show2} onHide={handleClose2}>
										<Modal.Header closeButton2>
											<Modal.Title>Reset Password</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<Form.Group className="mb-3" controlId="emailreq">
												<Form.Label>Email</Form.Label>
												<Form.Control
													name="emailreq"
													type="text"
													placeholder="Enter Email"
													// value={[loginValues["emailver"]] || ""}

													onChange={e => {
														resetHandleChange(e);
													}}
												/>
											</Form.Group>
										</Modal.Body>
										<Modal.Footer>
											<Button variant="secondary" onClick={handleClose2}>
												Close
											</Button>
											<Button
												variant="primary"
												onClick={e => {
													resetHandleSubmit(e);
													handleClose2(e);
												}}
											>
												Confirm
											</Button>
										</Modal.Footer>
									</Modal>
								</Form>
							</Form>
						</Tab>
						<Tab eventKey="signup" title="Signup">
							<Form
							//   action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSckuT7S7_mCj5QDPXAAkM_QfwuNmuyn41GhTlYfPJ40JVd8sg/formResponse"
							//   method="POST"
							//   target="contact-iframe"
							>
								<Form.Group className="mb-3" controlId="name">
									<Form.Label>Name</Form.Label>
									<Form.Control
										name="name"
										type="text"
										placeholder="Enter Name"
										// value={signupValues["name"] || ""}
										onChange={signupHandleChange}
									/>
								</Form.Group>
								<Form.Group className="mb-3" controlId="formBasicEmail">
									<Form.Label>Email address</Form.Label>
									<Form.Control
										name="email"
										type="email"
										placeholder="Enter email"
										value={signupValues["email"] || ""}
										onChange={e => {
											signupHandleChange(e);
											emailvalidation(e.target.value);
										}}
									/>

									<Form.Text className="text-muted">
										We'll never share your email with anyone else.
									</Form.Text>
								</Form.Group>
								<Form.Group className="mb-3" controlId="password1">
									<Form.Label>Password</Form.Label>
									<Form.Control
										name="password1"
										type="password"
										placeholder="Password"
										//   value={values["password"]}

										onChange={e => {
											validate(e.target.value);
											setformdata(e.target.value);
										}}
									/>
									{isvalid ? (
										<span style={{ color: "green", textWeight: "bold" }}>
											{errorMessage}
										</span>
									) : (
										<span style={{ color: "red", textWeight: "bold" }}>
											{errorMessage}
										</span>
									)}
								</Form.Group>
								<Form.Group>
									<Form.Label>Password</Form.Label>
									<Form.Control
										name="password"
										type="password"
										placeholder="Confirm Password"
										// value={signupValues["password"] || ""}
										onChange={e => {
											e.target.value === formdata
												? setIsmatch(true)
												: setIsmatch(false);
											//   ismatch&&isvalid?handleChange(e):handleChange(null)
											signupHandleChange(e);
										}}
									/>
								</Form.Group>
								{ismatch ? (
									<span style={{ color: "green", textWeight: "bold" }}>
										Matched
									</span>
								) : (
									<span style={{ color: "red", textWeight: "bold" }}>
										Not match
									</span>
								)}
								<Form.Group className="mb-3" controlId="contact">
									<Form.Label>Contact Number</Form.Label>
									<Row>
										<Col lg="4">
											<Form.Select
												name="ccode"
												// value={signupValues["ccode"] || ""}
												onChange={event => {
													setCountry(event.target.value || "IN");
													signupHandleChange(event);
												}}
											>
												<option value="">{"Country code"}</option>
												{getCountries().map(country => (
													<option key={country} value={country}>
														{en[country]} +{getCountryCallingCode(country)}
													</option>
												))}
											</Form.Select>
										</Col>
										<Col lg="8">
											<Form.Control
												name="mob"
												type="text"
												placeholder="9876543210"
												// value={signupValues["mob"] || ""}
												onChange={signupHandleChange}
											/>
										</Col>
									</Row>
								</Form.Group>
								<Form.Group className="mb-3" controlId="city">
									<Form.Label>City</Form.Label>
									<Form.Control
										name="city"
										type="text"
										placeholder="City"
										// value={signupValues["city"] || ""}
										onChange={signupHandleChange}
									/>
								</Form.Group>
								<Form.Group className="mb-3" controlId="city">
									<Form.Label>State</Form.Label>
									<Form.Control
										name="state"
										type="text"
										placeholder="State"
										// value={signupValues["state"] || ""}
										onChange={signupHandleChange}
									/>
								</Form.Group>

								{/* <Form.Group  className="mb-3" controlId="formBasicCheckbox">
              <Form.Check type="checkbox" label="Check me out" />
          </Form.Group> */}
								<div style={{textAlign:"center"}}>

											 <button onClick={e => {
												 signupHandleSubmit(e);
												}} class="button">Submit</button>
								
								</div>
								{/* <Button
									variant="primary"
									type="submit"
									onClick={e => {
										signupHandleSubmit(e);
									}}
								>
									Submit
								</Button> */}
							</Form>
							<GoogleFormHandler id="contact-iframe" title="Form Submitted">
								Your Query has been Submitted. You can now go to the home page.
							</GoogleFormHandler>
						</Tab>
					</Tabs>
				</Row>
			</>
		);
	}
}

export default Login;
