import React,{useState,useEffect} from 'react'
import { Row,Form,Button,Tabs,Tab,Col,Toast,ToastContainer,Modal} from 'react-bootstrap'
import instance from '../helpers/instance.js'
import validator from 'validator'
import { useSelector, useDispatch } from 'react-redux'
import {useNavigate} from "react-router-dom"
import { changeattempt } from '../reduxSlices/forgetpasswordSlice'
import useForm from '../modules/formWithSubmit/useForm'


function Forgetpassword() {

    
    const dispatch = useDispatch()
        
  const navigate = useNavigate();

  const attempts = useSelector((state)=> state.confirmpassword.value.attempts)
  const resetpassworddata = useSelector((state) => state.user.value.email)


      // const {values,handleChange,handleSubmit} = useForm(func)
      const [formdata, setformdata] = useState("")

      const [errorMessage, setErrorMessage] = useState('')
      const [isvalid, setIsvalid] = useState(false)
      const [ismatch, setIsmatch] = useState(false)
    

    
    
    const validate = (e) => {
    
      if (validator.isStrongPassword(e, {
        minLength: 8, minLowercase: 1,
        minUppercase: 1, minNumbers: 1, minSymbols: 1
      })) {
        setErrorMessage('Is Strong Password');
        setIsvalid(true);

      } else {

        
        let ermsg=''
        // console.log(e)
 
         let password=e
        //  console.log(password)
 
         let strengthValue = {
           'caps': false,
           'length': false,
           'special': false,
           'numbers': false,
           'small': false
         };
         let strengthIndicator = 0;
         if(password.length>=8)
         {strengthValue.length=true;
        //  console.log(password.length)
        }
         
         for(let index=0; index < password.length; index++) {
           let char = password.charCodeAt(index);
           if(!strengthValue.caps && char >= 65 && char <= 90) {
               strengthValue.caps = true;
           } else if(!strengthValue.numbers && char >=48 && char <= 57){
             strengthValue.numbers = true;
           } else if(!strengthValue.small && char >=97 && char <= 122){
             strengthValue.small = true;
           } else if(!strengthValue.numbers && char >=48 && char <= 57){
             strengthValue.numbers = true;
           } else if((!strengthValue.special && (char >=33 && char <= 47) )|| (char >=58 && char <= 64)) {
             strengthValue.special = true;
           }
 
           
           
         }
         for(let metric in strengthValue) {
           if(strengthValue[metric] === false) {
             
             if(metric!=="length")
             {strengthIndicator++;
             ermsg+=`\n 1 ${metric}   character ,`
            }
             
             
            }  
          }

          if(strengthIndicator>0)
          ermsg="Password must contain atleast "+ermsg;

          if(strengthValue["length"]===false)
          ermsg+=`\nLength should be greater than or equal to 8 .`

         if(strengthIndicator===0)
         setErrorMessage("Strong Password")
//  console.log("ermsg",ermsg)
 setErrorMessage(ermsg)
//  console.log("finished")
 
        setIsvalid(false);
        // setErrorMessage('Is Not Strong Password')
      
      }
    }
    const [resetpassword, setResetpassword] = useState(attempts)

    const func4 = async (data) =>{
        data.email=resetpassworddata
        console.log(data);
        const resp = await instance.post('/api/email/checkresetpassword',data);
        console.log(resp)
        if(resp.data==="success" || resp.data===0)
        {setResetpassword(0);
        navigate('/')}
        else 
        setResetpassword(resp.data)

        // }
      }
      
      const [reset2Values,reset2HandleChange,reset2HandleSubmit] = useForm(func4)

    return(
        <>
          <Row style={{margin:"5% 20% 2% 20%"}}>
            <div style={{fontSize:"1.6rem",textAlign:"left",marginBottom:"1%"}}>Reset Password</div>
            <Form style={{margin:"5% 0 0 0"}}>
                      <Form.Group as={Row} className="mb-3" controlId="emailreq">
                      <Form.Label column={4}>Email</Form.Label>
                      <Col sm={8}>
                      <Form.Control
                        name="emailreq"
                        
                        type="text"
                        placeholder={resetpassworddata}
                        disabled={true}
                        value={[reset2Values[{resetpassworddata}]] }
                        
                        
                        />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3" controlId="password1">
                  <Form.Label column={4}>Password</Form.Label>
                  <Col sm={8}>
                  <Form.Control
                    name="password1"
                    type="password"
                    placeholder="Password"
                    //   value={values["password"]}
                  
                    onChange={(e)=>{
                      validate(e.target.value);
                              setformdata(e.target.value);
                            }}
                            
                            />
                  </Col>
                  {isvalid?<span style={{color:"green",textWeight:"bold"}}>{errorMessage}</span>:<span style={{color:"red",textWeight:"bold"}}>{errorMessage}</span>}
                
                </Form.Group>
                <Form.Group as={Row}>
                <Form.Label column={4}>Confirm Password</Form.Label>
                  <Col sm={8}>
    
                  <Form.Control
                    name="password"
                    type="password"
                    placeholder="Confirm Password"
                    // value={signupValues["password"] || ""}
                    onChange={e=>{e.target.value===formdata?(setIsmatch(true)):(setIsmatch(false));
                  //   ismatch&&isvalid?handleChange(e):handleChange(null)
                  reset2HandleChange(e);
                  }
                  }
                  />
                  </Col>
                </Form.Group>
                  {ismatch?<span style={{color:"green",textWeight:"bold"}}>Matched</span>:<span style={{color:"red",textWeight:"bold"}}>Not match</span>}
    
                  <Form.Group as={Row}>
                <Form.Label column={4}>Passcode</Form.Label>
                  <Col sm={8}>
    
                  <Form.Control
                    name="passcode"
                    type="passcode"
                    placeholder="Passcode"
                    onChange={e=>
                  {reset2HandleChange(e);
                  }
                  }
                  />
                  </Col>
                  <div style={{textSize:"1.6rem"}}>No of Attempts left : {resetpassword}</div>
                </Form.Group>
                        <Button style={{marginTop:"2%"}} variant="primary" onClick={e=>{dispatch(changeattempt());reset2HandleSubmit(e);}}>
                          Confirm
                        </Button>
            </Form>
          </Row>
              
        </>
      );
}

export default Forgetpassword