import React,{useState,useEffect} from 'react'
import useFilter from "../modules/datatable/useFilter";
import Datatable from "../modules/datatable/Datatable";
import {Row,Col,Button,Modal,Table,Form,InputGroup} from 'react-bootstrap'
import {useNavigate,useSearchParams} from "react-router-dom"
import Sidepanel from './Sidepanel';
import '../sass/edit.scss'
import Asyncinput from '../modules/asyncinput/Asyncinput';
import Navbarcomp from './Navbarcomp';


function Investmenttable() {
  

  const navigate = useNavigate();

  // const handler = () =>{

  // }
  
	const [isMobile, setIsMobile] = useState(3);

	const handleResize = () => {
		if (document.documentElement.clientWidth >= 942) {
			setIsMobile(3);
		} else {
			setIsMobile(1);
		}
	};

  	useEffect(() => {
		handleResize();
		
	}, []);
	useEffect(() => {
		window.addEventListener("resize", handleResize);
  });
  
  let i=0;
  const [show, setShow] = useState(false);
  const [lvalue, setlvalue] = useState('');
  
  const [invdata, setInvdata] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


    const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  let [searchParams, setSearchParams] = useSearchParams();
  let payoutID = searchParams.get("payoutID");
  
  const [filtervalue, setfiltervalue] = useState({
    lvalue: '',     //payout remaining lower value
    uvalue: '',     //payout remaining upper value
    llimit: '',     //investment amount lower amount
    ulimit: '',      //investment amount upper limit
  });
  const [rateofinterest, setrateofinterest] = useState('');
  const [investmentnumber, setinvestmentnumber] = useState('');
  
  
  const [defaultfilter, setdefaultFilter] = useState({})
  
  let columns = [
    
         {
          "Header":"Investment Number",
          "Accessor":"investmentnumber",
          "sortable":true

        },
        {
          "Header": "Date of Investment",
          "Accessor": "investmentdate",
          "sortable":true,
          "displayFunction":function(data){
            let d = new Date(data.investmentdate)
            // console.log(d)
            return d.toLocaleString("en-UK",{dateStyle:"medium"})
          }
        },
        {
          "Header":"Investment Amount",
          "Accessor":"investmentamount",
          "sortable":true

        },
        {
          "Header": "Payout Amount",
          "Accessor": "payoutamount"
        },
        {
          "Header": "Rate of Interest",
          "Accessor": "rateofinterest",
          "defaultSort":true,
          // "sortOrder":"DESC"
        },
        {
          "Header": "Payout Remaining",
          "Accessor": "payoutremaining"
        },
        {
            "Header":"Next Payout",
            "Accessor":"nextpayout",
            "displayFunction":function(data){
              let d = new Date(data.nextpayout)
              // console.log(d)
              return d.toLocaleString("en-UK",{dateStyle:"medium"})
            }
        },
        {
          "Header":"Total Number of payout",
          "Accessor":"totalnoofpayouts"
        },
        {
          "Header":"Action",
          "Accessor":"",
          "defaultSort":false,
          "sort":false,
          "displayFunction":function(data){

            if(payoutID==='' || payoutID===null)
            { 
              return(
                <div style={{'display':'flex',
                'gridAutoFlow': 'column',
                'gridColumnGap': '10px',
                // alignContent:'center',
                'justifyContent':'center'}}>
                  <span><Button onClick={() => { handleShow(); setInvdata(data) }} ><i className="fas fa-eye"></i></Button></span>
                  
                  <span><Button href={'/api/investmentdetails/downloadPDF/' + data._id} target="_blank"><i className="fas fa-download"></i></Button></span>
                  
                  {/* <span><Button href={'http://localhost:4000/api/investmentdetails/downloadPDF/' + data._id} target="_blank"><i className="fas fa-download"></i></Button></span> */}

                  <span><Button onClick={()=>{setdefaultFilter({_id:data._id})
                  ;navigate(`/allpayout?investmentID=${data["_id"]}`,{state:{data:data}})} } >
                <i className="fas fa-external-link-alt"></i></Button></span>
                
                </div>
              );
             
            } else{ 
            return  <div style={{'display':'flex',
            'gridAutoFlow': 'column',
            'gridColumnGap': '10px',
            // alignContent:'center',
            'justifyContent':'center'}}>
              
                {/* <span><Button onClick={()=>{handleShow();setInvdata(data)} } ><i class="fas fa-eye"></i></Button></span> */}
             <span><Button onClick={() => { handleShow(); setInvdata(data) }} ><i className="fas fa-eye"></i></Button></span>
                  
                  <span><Button href={'/api/investmentdetails/downloadPDF/' + data._id} target="_blank"><i className="fas fa-download"></i></Button></span>
                  
              </div>
            }
          }
        }
        // {
        //   "Header": "DOB",
        //   "Accessor": "MPA",
        //   "displayFunction":function(data){
        //     return <span>{data.a} {data.b}</span>
        //   }
        // }
      ]

      useEffect(() => {
        
        handleFilterChange({
          "target":{
            "name":"payoutID",
            "value":payoutID
          }
        })
        var temp={...filter};
        setFilter({
          ...temp,
          payoutID
        })
       
       
      }, [searchParams])
  
  
  useEffect(() => {
     setfiltervalue((prev) => ({ ...prev, ...filter }));
  },[filter])
  console.log(filtervalue);
  console.log(filter);
  console.log(defaultfilter);

      var {filter, handleFilterSubmit, handleFilterChange,setFilter} = useFilter({payoutID});

      
      if(isMobile===3)
      return (
        <div style={{height:"100vh"}}> 
			<Row style={{height:"inherit"}}>
				
          {/* <div style={{borderBottom:'1px solid #999999'}}> */}

            <Col md={2} style={{ borderRight: "1px solid #999999" }}>
              						<div style={{ display:"block",marginTop:"7%",marginLeft:"auto",marginRight:"auto",width:"62%"}}>

                <Navbarcomp />
                </div>
      <Sidepanel/>
          </Col>
          <Col md={10}>
          <div className='a3'  style={{marginTop:"5%"}}>
            Investment Table
          {/* </div> */}
          </div>
      
         <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Investment Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      
        <Table>
        <thead>
        <tr>
          <th>Name</th>
          <th>Detail</th>
        </tr>
      </thead>
          {
             Object.entries(invdata).map((key,value)=>
            {
              var ob = {
                'noofbikes': 'Number of Bikes', 'investmentamount': 'Investment Amount', 'investmentdate': 'Investment Date', 'nextpayout': 'Next Payout', 'payoutamount': 'Payout Amount',
                'payoutremaining': 'Payout Remaining', 'rateofbike': 'Rate of Bike', 'rateofinterest': 'Rate of Interest', 'startingdate': 'Starting Date', 'tenure': 'Tenure',
                'totalnoofpayouts': 'Total Number of Payouts', 'enddate': 'Payout End Date', 'investmentnumber': 'Investment Number'
              }
              
              // var ob = ['object','_id','customerid','status','userid','investmenttype','bikenumber']
              if(key[0].includes('date') || key[0]==='nextpayout')
              {
                let d = new Date(key[1]);
                key[1]=d.toLocaleString("en-UK",{dateStyle:"medium"})
              }
              
              if(key[0] in ob)
              return(
                <tbody key={i++}>
            <tr>
              
                    <td>{ob[key[0]]||key[0]}</td>
                    <td>
                {key[1]}
                </td>
            </tr>
                </tbody>
              )
          
          })
        }
       

        </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
              </Modal>

              <div style={{textAlign:"right",margin:"1% 1% 0% 0"}}>

               <Button  variant="primary" onClick={handleShow2}>
                Filter
              </Button>
              </div>
              
               <Modal centered
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Investment Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <Form onSubmit={handleFilterSubmit}>

          <Row style={{margin:"2% 0 2% 0"}}>
            <Col sm={6}>
            {/* <Asyncinput api="" min="3" placeholder="newname"/> */}
            <div style={{margin:"1% 0 4% 0"}}>
              
              Rate of Interest
                        </div>
                        <Asyncinput
                          // value={filtervalue.rateofinterest}
                          cacheOptions defaultOptions
                          // onChange={
                          // (e) => { setfiltervalue(prev => ({ ...prev, rateofinterest: e.target.value })); }
                        // }
                          defaultvalue={rateofinterest}
                        newstate={setrateofinterest}  name="rateofinterest" api="/api/investmentdetails/rateofinterest" min="1" placeholder="rateofinterest" handle={ handleFilterChange} />
            </Col>

            <Col >
            
            <Form.Group className="mb-3">
            <Form.Label>Investment Amount</Form.Label>
                          <Form.Control style={{ margin: "1% 0 1% 0" }} placeholder="lower limit" value={filtervalue.llimit}
                            onChange={e => {
                              setfiltervalue((prev) => ({ ...prev, llimit: e.target.value }));
                              handleFilterChange(e);
                            }} name="llimit" />
                          <Form.Control placeholder="upper limit" value={filtervalue.ulimit} onChange={(e) => {
                            setfiltervalue((prev) => ({ ...prev, ulimit: e.target.value }));
                            handleFilterChange(e);
                          }} name="ulimit" />
            </Form.Group>
            {/* <Asyncinput name="rateofinterest" api="/api/investmentdetails/rateofinterest" min="1" placeholder="rateofinterest" handle={handleFilterChange} /> */}
            {/* <Asyncinput name="rateofinterest" api="/api/investmentdetails/rateofinterest" min="1" placeholder="rateofinterest" handle={handleFilterChange} /> */}
            </Col>
            <Col>
            <div style={{margin:"1% 0 4% 0"}}>
              
              Investmentnumber
              </div>
                        <Asyncinput
                          defaultvalue={ investmentnumber} newstate={setinvestmentnumber} name="investmentnumber" api="/api/investmentdetails/investmentnumber" min="1" placeholder="investmentnumber" handle={handleFilterChange} />
            </Col>
            <Col sm={6}>
            
            <Form.Group className="mb-3">
            <Form.Label>Payout Remaining</Form.Label>
            <Form.Control style={{ margin: "1% 0 1% 0" }} value={filtervalue.lvalue} placeholder="lower value" onChange={e=>{
                            setfiltervalue(prev => ({ ...prev, lvalue: e.target.value }));
                            handleFilterChange(e);
                          }
              // setlvalue(e.target.value);
            } name="lvalue" />
                          <Form.Control placeholder="upper value" value={filtervalue.uvalue} onChange={(e) => {
                            setfiltervalue(prev => ({ ...prev, uvalue: e.target.value }));
                            handleFilterChange(e);
                          }
                          } name="uvalue" />
            </Form.Group>
            </Col>
          </Row>
          
            {/* <input type="text" onChange={handleFilterChange} name="myName"></input>
            <input type="hidden" name="myNames" value="hii" onChange={handleFilterChange}></input> */}
            <Button type="submit" onClick={handleClose2}>Submit</Button>
          </Form>
          
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
        </Modal.Footer> */}
              </Modal>
          <div style={{borderBottom:'1px solid #999999',margin:"2% 0 2% 0"}}></div>
          {/* </FormWrapper> */}
              <div className="distanceTable" >
                {/* <ScrollMenu > */}

                <Datatable columns={columns} filter={filter} api="/api/investmentdetails/showdetails" method="POST" limit={10} sortable={true}></Datatable>
                {/* </ScrollMenu> */}

          </div>
          </Col>
        </Row>
    </div>
    )
      else {
              return (
        <div style={{height:"100vh"}}> 
			<Row style={{height:"inherit"}}>
				
          {/* <div style={{borderBottom:'1px solid #999999'}}> */}

            
              						<div style={{ display:"block",marginTop:"7%",marginLeft:"auto",marginRight:"auto",width:"45%"}}>

                <Navbarcomp />
                    </div>
                   <div style={{textAlign:"right",position:"absolute",top:'1%',right:"1%"}}>

      <Sidepanel/>
                    </div>
          

          <div className='a3'  style={{marginTop:"0%"}}>
            Investment Table
          {/* </div> */}
          </div>
      
         <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Investment Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      
        <Table style={{marginTop:"-5%"}}>
        <thead>
        <tr>
          <th>Name</th>
          <th>Detail</th>
        </tr>
      </thead>
          {
            Object.entries(invdata).map((key,value)=>
            {
              var ob = {
                'noofbikes': 'Number of Bikes', 'investmentamount': 'Investment Amount', 'investmentdate': 'Investment Date', 'nextpayout': 'Next Payout', 'payoutamount': 'Payout Amount',
                'payoutremaining': 'Payout Remaining', 'rateofbike': 'Rate of Bike', 'rateofinterest': 'Rate of Interest', 'startingdate': 'Starting Date', 'tenure': 'Tenure',
                'totalnoofpayouts': 'Total Number of Payouts', 'enddate': 'Payout End Date', 'investmentnumber': 'Investment Number'
              }
              
              // var ob = ['object','_id','customerid','status','userid','investmenttype','bikenumber']
              if(key[0].includes('date') || key[0]==='nextpayout')
              {
                let d = new Date(key[1]);
                key[1]=d.toLocaleString("en-UK",{dateStyle:"medium"})
              }
              
              if(key[0] in ob)
              return(
                <tbody key={i++}>
            <tr>
              
                    <td>{ob[key[0]]}</td>
                    <td>
                {key[1]}
                </td>
            </tr>
                </tbody>
              )
          
          })
        }
       

        </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
              </Modal>

              <div style={{textAlign:"right",margin:"1% 1% 0% 0"}}>

               <Button  variant="primary" onClick={handleShow2}>
                Filter
              </Button>
              </div>
              
               <Modal centered
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Investment Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <Form onSubmit={handleFilterSubmit}>

          <Row style={{margin:"2% 0 2% 0"}}>
            <Col sm={6}>
            {/* <Asyncinput api="" min="3" placeholder="newname"/> */}
            <div style={{margin:"1% 0 4% 0"}}>
              
              Rate of Interest
              </div>
            <Asyncinput defaultvalue={rateofinterest} newstate={setrateofinterest} name="rateofinterest" api="/api/investmentdetails/rateofinterest" min="1" placeholder="rateofinterest" handle={handleFilterChange} />
            </Col>

            <Col >
            
            <Form.Group className="mb-3">
            <Form.Label>Investment Amount</Form.Label>
            <Form.Control style={{ margin: "1% 0 1% 0" }} placeholder="lower limit" value={filtervalue.llimit}
                            onChange={e => {
                              setfiltervalue((prev) => ({ ...prev, llimit: e.target.value }));
                              handleFilterChange(e);
                            }} name="llimit" />
                          <Form.Control placeholder="upper limit" value={filtervalue.ulimit} onChange={(e) => {
                            setfiltervalue((prev) => ({ ...prev, ulimit: e.target.value }));
                            handleFilterChange(e);
                          }} name="ulimit" />
            </Form.Group>
            {/* <Asyncinput name="rateofinterest" api="/api/investmentdetails/rateofinterest" min="1" placeholder="rateofinterest" handle={handleFilterChange} /> */}
            {/* <Asyncinput name="rateofinterest" api="/api/investmentdetails/rateofinterest" min="1" placeholder="rateofinterest" handle={handleFilterChange} /> */}
            </Col>
            <Col>
            <div style={{margin:"1% 0 4% 0"}}>
              
              Investmentnumber
              </div>
            <Asyncinput defaultvalue={investmentnumber} newstate={setinvestmentnumber} name="investmentnumber" api="/api/investmentdetails/investmentnumber" min="1" placeholder="investmentnumber" handle={handleFilterChange}/>
            </Col>
            <Col sm={6}>
            
            <Form.Group className="mb-3">
            <Form.Label>Payout Remaining</Form.Label>
            <Form.Control style={{ margin: "1% 0 1% 0" }} value={filtervalue.lvalue} placeholder="lower value" onChange={e=>{
                            setfiltervalue(prev => ({ ...prev, lvalue: e.target.value }));
                            handleFilterChange(e);
                          }
              // setlvalue(e.target.value);
            } name="lvalue" />
           <Form.Control placeholder="upper value" value={filtervalue.uvalue} onChange={(e) => {
                            setfiltervalue(prev => ({ ...prev, uvalue: e.target.value }));
                            handleFilterChange(e);
                          }
                          } name="uvalue" />
            </Form.Group>
            </Col>
          </Row>
          
            {/* <input type="text" onChange={handleFilterChange} name="myName"></input>
            <input type="hidden" name="myNames" value="hii" onChange={handleFilterChange}></input> */}
            <Button type="submit" onClick={handleClose2}>Submit</Button>
          </Form>
          
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
        </Modal.Footer> */}
              </Modal>

                 {/* <div>
                <Scroll/>
              </div> */}
          <div style={{borderBottom:'1px solid #999999',margin:"2% 0 2% 0"}}></div>
          {/* </FormWrapper> */}
                    <div className="distanceTable">
                      {/* <ScrollMenu> */}

                     <Datatable columns={columns} filter={filter} api="/api/investmentdetails/showdetails" method="POST" limit={5} sortable={true}></Datatable>
                      {/* </ScrollMenu> */}
          </div>
          
        </Row>
    </div>
    )
  }
}

export default Investmenttable