import React, { useEffect, useState } from "react";
// import logo from '../assets/svg/logo.svg';
import "../sass/app.scss";
import Footer from "./static/Footer";
import Nav from "./Navcomp";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Container } from "react-bootstrap";
import FAQ from "./static/FAQ";
import Terms from "./static/Terms";
import Privacy from "./static/Privacy";
import Login from "./Login";
import Tasks from "./Tasks";
import Dashboard from "./Dashboard";
import Edit from "./Edit";
import Investmenttable from "./Investmenttable";
import Forgetpassword from "./Forgetpassword";
import Allpayout from "./Allpayout";
import Manualinvestment from "./Manualinvestment";
import Asyncinput from "../modules/asyncinput/Asyncinput";

function App() {
	const [isMobile, setIsMobile] = useState(3);
	//choose the screen size
	const handleResize = () => {
		if (document.documentElement.clientWidth >= 1296) {
			setIsMobile(3);
		} else if (document.documentElement.clientWidth >= 800) {
			setIsMobile(2);
		} else {
			setIsMobile(1);
		}
	};

	// create an event listener
	useEffect(() => {
		window.addEventListener("resize", handleResize);
	});
	useEffect(() => {
		handleResize();
	}, []);

	return (
		<div className="App">
			<Container fluid>
				<BrowserRouter>
					{/* <Nav/> */}
					<Routes>
						{/* <Route path="/" element={<Nav/>} /> */}
						<Route path="/FAQ" element={<FAQ ss={isMobile} />} />
						<Route path="/Terms" element={<Terms />} />
						<Route exact path="/" element={<Login />} />
						<Route path="/Tasks" element={<Tasks />} />
						<Route path="/Privacy" element={<Privacy />} />
						<Route path="/Dashboard" element={<Dashboard />} />
						<Route path="/edit" element={<Edit />} />
						<Route path="/investmenttable" element={<Investmenttable />} />
						<Route path="/allpayout" element={<Allpayout />} />
						<Route path="/forgetpassword" element={<Forgetpassword />} />
						<Route path="" element={<Asyncinput />} />
						<Route path="/manualinvestment" element={<Manualinvestment/>}/>
						{/* <Route path="/" element={<Footer/>} /> */}
					</Routes>
					<Footer />
				</BrowserRouter>
			</Container>
		</div>
	);
}

export default App;
