import React,{useEffect,useState} from 'react';
import img1 from '../assets/logo/edrives_blk.png'
import { Offcanvas,Navbar,Nav,Container,NavDropdown} from 'react-bootstrap'
import {Link,useLocation} from "react-router-dom";

function Navcomp() {

   
  const [small, setSmall] = useState(false);
  let location=useLocation();

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSmall(window.pageYOffset > 50)
      );
    }
  }, []);

    useEffect(() => {
      setTimeout(function () {
        const href = location.hash.replace("#", "");
        const element = document.getElementById(href);
        if (element) {
          element.scrollIntoView();
        }else{
          window.scroll(0,0)
        }
      }, 200);
    }, [location]);

  const [isMobile, setIsMobile] = useState(false)
 
  //choose the screen size 
  const handleResize = () => {
    if (document.documentElement.clientWidth <= 1200) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
   
  }
  
  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })
  useEffect(() => {
    
    handleResize();
  }, []);
  
  // const abc={backgroundColor:"white"}
  if(!isMobile)
  return( <div>
    <Navbar fixed="top" collapseOnSelect expand="lg"   bg={small?"white":"none"} >
  <Container>
  <Navbar.Brand to="/" as={Link}>
  {
    small?(<img
      src={img1}
      width="30"
      height="30"
      className="d-inline-block align-top"
      alt="React Bootstrap logo"
    />):null
  }
  
  </Navbar.Brand>
  
    {/* <Nav className="me-auto"></Nav> */}
    <Nav  className="justify-content-end" style={{ width: "100%" }}  >
    </Nav>

 
  </Container>
</Navbar>
</div>);
else
return (
<div >
<Navbar bg="none"  expand={false}>
  <Container fluid>
    <Navbar.Brand as={Link} to="/"></Navbar.Brand>
    <Navbar.Toggle aria-controls="offcanvasNavbar" />
    <Navbar.Offcanvas
      id="offcanvasNavbar"
      aria-labelledby="offcanvasNavbarLabel"
      placement="end"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title id="offcanvasNavbarLabel">E DRIVES</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
      <Nav className="justify-content-end flex-grow-1 pe-3">
      </Nav>
       
      </Offcanvas.Body>
    </Navbar.Offcanvas>
  </Container>
</Navbar>

  </div>);
}

export default Navcomp;
