import React,{useState} from 'react'
import AsyncSelect from 'react-select/async'
import instance from './../../helpers/instance'
import useFilter from "./../datatable/useFilter";

function Asyncinput(props) {

  const [selectedOption, setSelectedOption] = useState({label:props.defaultvalue||''});
  // var {filter, handleFilterSubmit, handleFilterChange} = useFilter();

  const fetchData = (inputValue, callback) => {
    
    // console.log(inputValue)
    setTimeout(async()=>{
        // console.log('fff',inputValue.length)
        if(props.min>inputValue.length)
      callback([]);
      else
      {
        let res = await instance.post(`${props.api}`,{value:inputValue});
        // console.log(res.data)
          callback(res.data)
      }
    },1000)
}
    const onSearchChange = (selectedOption) => {
        // console.log(selectedOption)
      if (selectedOption) {
        if(props.newstate!==undefined)
        props.newstate(selectedOption.label);
          setSelectedOption(
            selectedOption
          );
        }
      };
  return (
    <div>
       {/* {props.placeholder} */}
         <AsyncSelect
            name={props.name}
        value={selectedOption}
            loadOptions={fetchData}
            placeholder={props.placeholder}
            onChange={(e,type) => {
              onSearchChange(e);
              let event={
                target:{
                  name:props.name,
                  value:e.value
                }
              }
              props.handle(event);

            }}
            // defaultOptions={true}
          />
    </div>
  )
}

export default React.memo(Asyncinput)