import React,{useState,useEffect} from 'react'
import { Link} from 'react-router-dom'
import { Col,Offcanvas,Button,Modal,Table,Row } from 'react-bootstrap'
import instance from '../helpers/instance'
import { useNavigate } from "react-router-dom"
import Newinvestment from './Newinvestment'
import { useSelector, useDispatch } from "react-redux";
import { adddetails } from '../reduxSlices/userSlice'

// const instance = require('../helpers/instance')
function Sidepanel() {

  const navigate = useNavigate();
  	const dispatch = useDispatch();
    const data = useSelector(state => state.user.value )
    const [state, setState] = useState(1);
    const [verified, setverified] = useState(data.emailVerified===true && data.kycverified===2 && data.bankdetailsverified===2);
    
    const [acdata, setAcdata] = useState({})
    const [bikeinfo, setBikeinfo] = useState({})
    const [show2, setShow2] = useState(false);
    const [show4, setShow4] = useState(false);


    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [show3, setShow3] = useState(false);

    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);

    
  const [isMobile, setIsMobile] = useState(3)

  const handleResize = () => {


    if (document.documentElement.clientWidth >= 942) {
      setIsMobile(3)
  }
  else
  {setIsMobile(1)}
  }

  const info = async() =>{
    let ver = false;
    let bikedata = await instance.get('/api/auth/bikedetail');
    setBikeinfo(bikedata.data);
    const userdetails = await instance.get('/api/auth/getuser');
				dispatch(adddetails(userdetails.data.user));

    let emailVerified = userdetails.data.user.emailVerified;
    let kycVerified = userdetails.data.user.tasks.kyc.verified;
    let bankVerified = userdetails.data.user.tasks.bankDetails.added;
    if( bankVerified===2 &&kycVerified ===2 && emailVerified===true  )
    {

      setverified(true);
      ver = true;
      
    }


  }
  useEffect(async() => {
    handleResize();
    info();

  }, []);
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })
 
    const handler = async() =>{
      await instance.delete('/api/auth/logout');
      
      navigate('/')
      
    }



    const valuehandler = (val) =>{

      if(isNaN(val)===false)
      {let num = Number(val);
      let newnum = Number((Math.max(0,Math.min(num,50))));
      setState(newnum)}
    }

    const handleSubmit = () => {
      var data = { bikes:state }
      instance.post('/api/investmentdetails/newinvestment',data);
      handleClose3();
      handleShow4();

      
    }
    // const [values,handleChange,handleSubmit] = useForm(func)
    if(isMobile===3)
  {
    if(verified===true)
    {
      return (
        <div>
        
        <Col >
            
            {
              show3===true||show4===true?
                <Newinvestment setShow3={setShow3} show3={show3} setShow4={setShow4} show4={show4}  bikeinfoo={bikeinfo} />
                :<></>
          }
            

            <div style={{margin:"40% 0% 0 0",textAlign:"center"}}>
              <div style={{marginBottom:"5%",textAlign:"center",borderBottom:"1px solid #999999"}}>
                  <Link to="/Dashboard" className='s3' >
                Dashboard<br/>
                  </Link>
              </div>
            <div className='s4' style={{marginBottom:"5%",textAlign:"center",borderBottom:"1px solid #999999"}}>

                <Link to="/edit" className='s3'   >
              Edit Profile
                </Link>

            </div>
            <div className='s4' style={{marginBottom:"5%",textAlign:"center",borderBottom:"1px solid #999999"}}>

            <Link to="/investmenttable" className='s3'   >
            Investment Table
            </Link>

            </div>
            <div className='s4' style={{marginBottom:"5%",textAlign:"center",borderBottom:"1px solid #999999"}}>

            <Link to="/allpayout" className='s3'   >
            Payouts
            </Link>

            </div>
            <div  style={{marginBottom:"5%",textAlign:"center",borderBottom:"1px solid #999999"}}>
           
    
                <Button className='s3' style={{ backgroundColor: "white", color: "black", border: "none" }} onClick={handleShow3}>
              New Investment
            </Button>              

            </div>
            <div  style={{marginBottom:"5%",textAlign:"center",borderBottom:"1px solid #999999"}}>
           
            <Button onClick={handler} className='s3'  style={{backgroundColor:"white",color:"black",border:"none"}} >
              Logout
            </Button>              
            

            </div>
            </div>
        </Col>
      
        </div>    
      )
    }
    else
    {
      return(

    <div>
        
          <Col >

          <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title>E Drive Account Details</Modal.Title>

        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>Name</Col>
            <Col>{acdata.name}</Col>
          </Row>
          <Row>
            <Col>IFSC</Col>
            <Col>{acdata.ifsc}</Col>
          </Row>
          <Row>
            <Col>Account Number</Col>
            <Col>{acdata.accountnumber}</Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose4}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
            <Modal show={show3} onHide={handleClose3}>
                <Modal.Header closeButton>
                <Modal.Title>Investment Plan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{textAlign:"center",margin:"0 0 5px 0"}}>
                    Number of Vehicles - {" "}
                <input type="string" 
                // min={1} max={50}
                 value={state}
               onChange={e=>valuehandler(e.target.value)} />
                    </div>

                <Table striped bordered hover >
                <tbody>
                    <tr>
                        <td>IRR</td>
                        <td>{bikeinfo.IRR}%</td>
                    </tr>
                    <tr>
                        <td>Tenure</td>
                        <td>{bikeinfo.Tenure}months</td>
                    </tr>
                    <tr>
                        <td>Investment Amount</td>
                        <td>{bikeinfo.InvestmentAmount*state}</td>
                    </tr>
                    <tr>
                        <td>Monthly Payouts</td>
                        <td>{bikeinfo.monthlypayout*state}</td>
                    </tr>
                    <tr>
                        <td>Total Payment</td>
                        <td>{bikeinfo.TotalPayment *state}</td>
                    </tr>
                    <tr>
                        <td>Total Profit</td>
                        <td>{bikeinfo.TotalProfit*state}</td>
                    </tr>
                    
                </tbody>
                </Table>

                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSubmit}>Payment</Button>
                <Button variant="secondary" onClick={handleClose3}>
                    Close
                </Button>

                </Modal.Footer>
            </Modal>
              <div style={{margin:"40% 0% 0 0",textAlign:"center"}}>
                <div style={{marginBottom:"5%",textAlign:"center",borderBottom:"1px solid #999999"}}>
                    <Link to="/Tasks" className='s3' >
                  Tasks to Complete<br/>
                    </Link>
                </div>
              
              <div  style={{marginBottom:"5%",textAlign:"center",borderBottom:"1px solid #999999"}}>
             
              <Button onClick={handler} className='s3'  style={{backgroundColor:"white",color:"black",border:"none"}} >
                Logout
              </Button>              
              

              </div>
              </div>
          </Col>
        
    </div>
      )

    }

  }
  else
  {
    if(verified===true)
    {
      return(
            <div >
             {
              show3===true||show4===true?
                <Newinvestment setShow3={setShow3} show3={show3} setShow4={setShow4} show4={show4} bikeinfoo={bikeinfo} />
                :<></>
          }
         
            <Button variant="primary" onClick={handleShow2}>
            Menu
          </Button>
    
          <Offcanvas show={show2} onHide={handleClose2}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Menu</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
           
            <div  style={{marginBottom:"1%",textAlign:"center",borderBottom:"1px solid #999999"}}>
    
              <Link className='s3' to="/dashboard" style={{textAlign:"left",textDecoration:'none',color:"black", margin:"5% 0 5% 0"}}>
            Dashboard<br/>
              </Link>
            </div>

            <div  style={{marginBottom:"1%",textAlign:"center",borderBottom:"1px solid #999999"}}>
    
              <Link className='s3' to="/edit" style={{textAlign:"left",textDecoration:'none',color:"black", margin:"5% 0 5% 0"}}>
             Edit Profile<br/>
              </Link>
            </div>
            
            <div style={{marginBottom:"1%",textAlign:"center",borderBottom:"1px solid #999999"}}>
               <Link className='s3' to="/investmenttable" style={{textAlign:"left",textDecoration:'none',color:"black", margin:"5% 0 5% 0"}}>
             Investment Table<br/>
              </Link>
            </div>
            <div  style={{marginBottom:"1%",textAlign:"center",borderBottom:"1px solid #999999"}}>
    
              <Link className='s3' to="/allpayout" style={{textAlign:"left",textDecoration:'none',color:"black", margin:"5% 0 5% 0"}}>
             Payout Table<br/>
              </Link>
            </div>
              <div  style={{marginBottom:"1%",textAlign:"center",borderBottom:"1px solid #999999"}}>
                 
                 <Button className='s3'  style={{backgroundColor:"white",color:"black",border:"none"}} onClick={handleShow3}>
                   New Investment
                 </Button>              
    
                 </div>
                 <div  style={{marginBottom:"1%",textAlign:"center",borderBottom:"1px solid #999999"}}>
                 
                 <Button onClick={handler} className='s3'  style={{backgroundColor:"white",color:"black",border:"none"}}>
                   Logout
                 </Button>              
     
                 </div>
            </Offcanvas.Body>
          </Offcanvas>
          </div>
    
      )

    }
    else
    {
      return(
        <div >
            <Modal show={show3} onHide={handleClose3}>
                <Modal.Header closeButton>
                <Modal.Title>Investment Plan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{textAlign:"center",margin:"0 0 5px 0"}}>
                Number of Vehicles - {" "}
                <input type="number" min={1} max={50} value={state}
               onChange={e=>setState(Math.max(1,Math.min(e.target.value,50)))} />
                    </div>
                <Table striped bordered hover >
                <tbody>
                    <tr>
                        <td>IRR</td>
                        <td>{bikeinfo.IRR}%</td>
                    </tr>
                    <tr>
                        <td>Tenure</td>
                        <td>{bikeinfo.Tenure}months</td>
                    </tr>
                    <tr>
                        <td>Investment Amount</td>
                        <td>{bikeinfo.InvestmentAmount*state}</td>
                    </tr>
                    <tr>
                        <td>Monthly Payouts</td>
                        <td>{bikeinfo.monthlypayout*state}</td>
                    </tr>
                    <tr>
                        <td>Total Payment</td>
                        <td>{bikeinfo.TotalPayment *state}</td>
                    </tr>
                    <tr>
                        <td>Total Profit</td>
                        <td>{bikeinfo.TotalProfit*state}</td>
                    </tr>
                    
                </tbody>
                </Table>

                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose3}>
                    Close
                </Button>

                </Modal.Footer>
            </Modal>            
        <Button variant="primary" onClick={handleShow2}>
        Menu
      </Button>

      <Offcanvas show={show2} onHide={handleClose2}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <div  style={{marginBottom:"3%",textAlign:"center",borderBottom:"1px solid #999999"}}>

          <Link className='s3' to="/" style={{textAlign:"left",textDecoration:'none',color:"black"}}>
         Tasks to Complete<br/>
          </Link>
        </div>

       
             <div  style={{marginBottom:"1%",textAlign:"center",borderBottom:"1px solid #999999"}}>
             
             <Button onClick={handler} className='s3'  style={{backgroundColor:"white",color:"black",border:"none"}}>
               Logout
             </Button>              
 
             </div>
        </Offcanvas.Body>
      </Offcanvas>
      </div>

  )
    }
  }
}

export default Sidepanel