import React, { useEffect, useState } from "react";
import { Row, Image, Col, Button, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../sass/dashboard.scss";
import img from "../assets/dashboard/img.png";
import Sidepanel from "./Sidepanel";
import instance from "../helpers/instance";
import Navbarcomp from "./Navbarcomp";
function Dashboard() {
	const [num, setnum] = useState(0);
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [bikes, setBikes] = useState(0);
	const [month, setMonth] = useState("");
	const [totalreturns, setTotalreturns] = useState(0);
	const [totalamountrealised, setTotalamountrealised] = useState(0);
	const [outstanding, setOutstanding] = useState(0);
	
	
	const [payout, setPayout] = useState(0);
	const [payoutremaining, setPayoutremaining] = useState(0);
	const [payoutpaid, setPayoutpaid] = useState(0);
	
	

	const [isMobile, setIsMobile] = useState(3);

	const handleResize = () => {
		if (document.documentElement.clientWidth >= 942) {
			setIsMobile(3);
		} else {
			setIsMobile(1);
		}
	};

	const getdata = async () => {
		const data = await instance.get("/api/investmentdetails/dashboard");

		setBikes(data.data.bikes);
		setPayout(data.data.payout);
		setPayoutpaid(data.data.payoutpaid);
		setPayoutremaining(data.data.payoutremaining);
		setMonth(data.data.month)
		setTotalreturns(data.data.totalreturns);
		setTotalamountrealised(data.data.totalamountrealised);
		setOutstanding(data.data.outstanding);
	};
	useEffect(() => {
		handleResize();
		getdata();
	}, []);
	useEffect(() => {
		window.addEventListener("resize", handleResize);
	});

	if (isMobile === 1)
		return (
			<div>
				
				<div style={{ display:"block",marginTop:"7%",marginLeft:"auto",marginRight:"auto",width:"45%"}}>

						<Navbarcomp/> 
					</div>

    			
				<div style={{textAlign:"right"}}>

				<Sidepanel />
				</div>
				<Row>
					<div
						style={{
							margin: "4% 0 4% 2%",
							textAlign: "left",
							fontSize: "1.4rem",
							fontWeight: "bold",
						}}
					>
						Your Dashboard
					</div>
					<div style={{ margin: "0% 0% 2% 28%" }}>
						<Image src={img} />
					</div>
				</Row>
				<Row>
					<Col>
						<div className="s1">Number Of Vehicles</div>
					</Col>
					<Col >
						<div className="s2">
						{bikes}
						</div>
					</Col>
				</Row>
				<Row
					style={{ margin: "0% 15% 0 10%", borderBottom: "1px solid #999999" }}
				></Row>

				<Row>
					<Col>
						<div className="s1">Total Payments Received</div>
					</Col>
					<Col>
						<div className="s2">
							{" "}
							{"\u20B9"} {75000 * bikes}
						</div>
					</Col>
				</Row>
				<Row
					style={{
						margin: "0% 15% 0 10%",
						borderBlockEnd: "1px solid",
						opacity: "30%",
					}}
				></Row>

				<Row>
					<Col>
						<div className="s1">
							<i className="fa-regular fa-indian-rupee-sign"></i>This Month’s
							Installment
						</div>
					</Col>
					<Col>
						<div className="s2">
							{"\u20B9"}
							{payout}
						</div>
					</Col>
				</Row>
				<Row
					style={{
						margin: "0% 15% 0 10%",
						borderBlockEnd: "1px solid",
						opacity: "30%",
					}}
				></Row>

				<Row >
					<Col>
						<div className="s1">Total Profit</div>
					</Col>
					<Col>
						<div className="s2">
							{" \u20B9"}
							{24973 * bikes}
						</div>
					</Col>
				</Row>
				<Row
							style={{
								margin: "0% 20% 0 15%",
								borderBlockEnd: "1px solid",
								opacity: "30%",
							}}
						></Row>
				
										<Row>
											<Col>
												<div className="s1">Payouts Received</div>
											</Col>
											<Col>
												<div className="s2">
													{" "}
													 { payoutremaining}
												</div>
											</Col>
										</Row>
										<Row
											style={{
												margin: "0% 20% 0 15%",
												borderBlockEnd: "1px solid",
												opacity: "30%",
											}}
										></Row>

						<Row style={{ marginBottom: "10%" }}>
							<Col>
								<div className="s1">Payouts Dispersed</div>
							</Col>
							<Col>
								<div className="s2">
									
									{ payoutpaid}
								</div>
							</Col>
						</Row>
			</div>
		);
	else
		return (
			<div style={{height:"100vh" }}>
				<Row style={{height:'inherit'}}>
    			{/* <Row style={{margin:"0% 0% 0 0%",borderBottom:"2px solid #999999"}}></Row> */}
				
					<Col md={2} style={{ borderRight: "1px solid #999999" }}> 
						<div style={{ display:"block",marginTop:"7%",marginLeft:"auto",marginRight:"auto",width:"62%"}}>
				<Navbarcomp />
						</div>

						<Sidepanel />
					</Col>
					<Col md={10} >
						<Row>
							<div
								style={{
									margin: "2% 0 0% 2%",
									textAlign: "left",
									fontSize: "1.4rem",
									fontWeight: "bold",
									borderBottom:'5px'
								}}
							>
								Your Dashboard
							</div>
							<div style={{ margin: "0% 0% 2% 28%" }}>
								<Image src={img} />
							</div>
						</Row>
						<Row style={{marginTop:"2%"}}>
							<Col>
								<div className="s1">Number Of Vehicles</div>
							</Col>
							<Col>
							<div className="s2">
						{bikes}
						</div>
							</Col>
						</Row>
						<Row
							style={{
								margin: "0% 20% 0 15%",
								borderBlockEnd: "1px solid",
								opacity: "30%",
							}}
						></Row>
						
						<Row>
							<Col>
								<div className="s1">Total Investment Made</div>
							</Col>
							<Col>
								<div className="s2">
									{" "}
									{"\u20B9"} {75000 * bikes}
								</div>
							</Col>
						</Row>
						
					

						
						<Row
							style={{
								margin: "0% 20% 0 15%",
								borderBlockEnd: "1px solid",
								opacity: "30%",
							}}
						></Row>
						
						<Row>
							<Col>
								<div className="s1">Total Returns</div>
							</Col>
							<Col>
								<div className="s2">
									{" "}
									{"\u20B9"} {totalreturns}
								</div>
							</Col>
						</Row>
							<Row
							style={{
								margin: "0% 20% 0 15%",
								borderBlockEnd: "1px solid",
								opacity: "30%",
							}}
						></Row>
						<Row>
							<Col>
								<div className="s1">
									<i className="fa-regular fa-indian-rupee-sign"></i>
									{ month} Installment
								</div>
							</Col>
							<Col>
								<div className="s2">
									{"\u20B9"} {payout}
								</div>
							</Col>
						</Row>
	 					<Row
							style={{
								margin: "0% 20% 0 15%",
								borderBlockEnd: "1px solid",
								opacity: "30%",
							}}
						></Row>
				
										<Row>
											<Col>
												<div className="s1">Payouts left</div>
											</Col>
											<Col>
												<div className="s2">
													{" "}
													 { payoutremaining}
												</div>
											</Col>
										</Row>


						<Row
											style={{
												margin: "0% 20% 0 15%",
												borderBlockEnd: "1px solid",
												opacity: "30%",
											}}
										></Row>

						<Row >
							<Col>
								<div className="s1">Payouts Dispersed</div>
							</Col>
							<Col>
								<div className="s2">
									
									{ payoutpaid}
								</div>
							</Col>
						</Row>
																<Row
											style={{
												margin: "0% 20% 0 15%",
												borderBlockEnd: "1px solid",
												opacity: "30%",
											}}
										></Row>


						<Row >
							<Col>
								<div className="s1">Total Outstanding Receivables</div>
							</Col>
							<Col>
								<div className="s2">
									
									{"\u20B9"} { outstanding}
								</div>
							</Col>
						</Row>
						<Row
											style={{
												margin: "0% 20% 0 15%",
												borderBlockEnd: "1px solid",
												opacity: "30%",
											}}
										></Row>

						<Row style={{ marginBottom: "10%" }}>
							<Col>
								<div className="s1">Total Amount Realized</div>
							</Col>
							<Col>
								<div className="s2">
									
									{"\u20B9"} { totalamountrealised}
								</div>
							</Col>
						</Row>

						
					</Col>
				</Row>
			</div>
		);
}

export default Dashboard;
