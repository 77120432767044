import React, { useState, useEffect } from "react";
import useFilter from "../modules/datatable/useFilter";
import Datatable from "../modules/datatable/Datatable";
import {
	Row,
	Col,
	Button,
	Modal,
	Table,
	Form,
	InputGroup,
} from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import Sidepanel from "./Sidepanel";
import "../sass/edit.scss";
import Asyncinput from "../modules/asyncinput/Asyncinput";
import Navbarcomp from "./Navbarcomp";

function Allpayout() {
	const navigate = useNavigate();
	let [searchParams, setSearchParams] = useSearchParams();
	// const [investmentID, setInvestmentID] = useState('')

	// useEffect(() => { 

	//   let investmentId = searchParams.get("investmentID") || "";
	//   console.log(investmentId)
	//   setInvestmentID(investmentId)
	//   console.log(investmentID)

	// }, [])

	let investmentID = searchParams.get("investmentID") || "";
	const [payoutid, setpayoutid] = useState('');;
	const [modeofpayment, setmodeofpayment] = useState('');
	
	
	// const handler = () =>{

	// } 
	let i = 0;
	const [show, setShow] = useState(false);
	const [invdata, setInvdata] = useState({});
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const [show2, setShow2] = useState(false);

	const handleClose2 = () => setShow2(false);
	const handleShow2 = () => setShow2(true);

	const [defaultfilter, setdefaultFilter] = useState({});
	let columns = [];
	if(investmentID===undefined || investmentID==='' || investmentID===null)
	columns = [
		{
			Header: "Payout Number",
			Accessor: "payoutid",
		},
		{
			Header: "Date of Payout",
			Accessor: "createdat",
			sortable: true
		},
		{
			Header: "Payout Amount",
			Accessor: "amount",
			sortable: true,
		},

		{
			Header: "Account number / UPI",
			Accessor: "amount",
			defaultSort: true,
			displayFunction: function (data) {
				if (data.Acno !== undefined) return <span>{data.Acno}</span>;
				else return <span>{data.upi}</span>;
			},
		},
		{
			Header: "Mode",
			Accessor: "mode",
		},
		{
			Header: "Action",
			Accessor: "",
			defaultSort: false,
			sort: false,
			displayFunction: function (data) {
				if (investmentID === "") {
					return (
						<div
							style={{
								display: "flex",
								gridAutoFlow: "column",
								gridColumnGap: "10px",
								// alignContent:'center',
								justifyContent: "center",
							}}
						>
							{/* <span><Button onClick={()=>{handleShow();setInvdata(data)} } ><i class="fas fa-eye"></i></Button></span> */}
							<span>
								<Button
									onClick={() => {
										navigate(`/investmenttable?payoutID=${data["_id"]}`);
										// ;navigate('/payouttable',{state:{data:data}})
									}}
								>
									<i className="fas fa-external-link-alt"></i>
								</Button>
							</span>
						</div>
					);
				} 
			},
		},
		];
	else
		columns = [
		{
			Header: "Payout Number",
			Accessor: "payoutid",
		},
		{
			Header: "Date of Payout",
			Accessor: "createdat",
			sortable: true
		},
		{
			Header: "Payout Amount",
			Accessor: "amount",
			sortable: true,
		},

		{
			Header: "Account number / UPI",
			Accessor: "amount",
			defaultSort: true,
			displayFunction: function (data) {
				if (data.Acno !== undefined) return <span>{data.Acno}</span>;
				else return <span>{data.upi}</span>;
			},
		},
		{
			Header: "Mode",
			Accessor: "mode",
		},
		
	];
	console.log(investmentID);
	var { filter, handleFilterSubmit, handleFilterChange, setFilter } = useFilter(
		{ investmentID }
	);

		const [isMobile, setIsMobile] = useState(3);

	const handleResize = () => {
		if (document.documentElement.clientWidth >= 942) {
			setIsMobile(3);
		} else {
			setIsMobile(1);
		}
	};

  	useEffect(() => {
		handleResize();
		
	}, []);
	useEffect(() => {
		window.addEventListener("resize", handleResize);
  });
	useEffect(
		function () {
			handleFilterChange({
				target: {
					name: "investmentID",
					value: investmentID,
				},
			});
			var temp = { ...filter };
			setFilter({
				...temp,
				investmentID,
			});
		},
		[investmentID]
	);
	if(isMobile===3)
	{return (
		<div style={{height:"100vh"}}>
			
			
			<Row style={{height:"inherit"}}>
				 <Col md={2} style={{ borderRight: "1px solid #999999" }}>
              						<div style={{ display:"block",marginTop:"7%",marginLeft:"auto",marginRight:"auto",width:"62%"}}>

                <Navbarcomp />
                </div>
      <Sidepanel/>
          </Col>
				<Col md={10}>
				<div className="a3" style={{marginTop:"5%"}}>Payout Table</div>
					<Modal show={show2} onHide={handleClose2} centered>
        <Modal.Header closeButton>
          <Modal.Title>Investment Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      {/* {new Date(invdata.investmentdate)} */}
        <Table>
        <thead>
        <tr>
          <th>Name</th>
          <th>Detail</th>
        </tr>
      </thead>
          {
            Object.entries(invdata).map((key,value)=>
            {
              var ob = {'object':'','_id':'','customerid':'','status':'','userid':'','bikenumber':'','investmenttype':''}
              
              // var ob = ['object','_id','customerid','status','userid','investmenttype','bikenumber']
              if(key[0].includes('date') || key[0]==='nextpayout')
              {
                let k=new Date(key[1]);
                key[1]=k.getDate()+"-"+k.getMonth()+"-"+k.getFullYear()
              }
              
              if(!(key[0] in ob))
              return(
                <tbody key={i++}>
            <tr>
              
              <td>{(key[0])}</td>
              <td>
                {key[1]}
                {
                  console.log(typeof(key[1]))
                }
                </td>
            </tr>
            </tbody>)
          
          })
        }
       

        </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
              </Modal>
              <div style={{textAlign:"right",margin:"1% 1% 0% 0"}}>

               <Button  variant="primary" onClick={handleShow2}>
                Filter
              </Button>
              </div>
              
               <Modal centered
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >

				        <Modal.Header closeButton>
						<Modal.Title>Payout Filter</Modal.Title>
						</Modal.Header>
						<Modal.Body>
					<Form onSubmit={handleFilterSubmit}>
						<Row style={{ margin: "2% 0 2% 0" }}>
							<Col>
								{/* <Asyncinput api="" min="3" placeholder="newname"/> */}
								<div style={{ margin: "1% 0 4% 0" }}>Payout Id</div>
								<Asyncinput
											name="payoutid"
											defaultvalue={payoutid}
											newstate={setpayoutid}
									api="/api/payout/payoutid"
									min="1"
									placeholder="payoutid"
									handle={handleFilterChange}
								/>
							</Col>

							<Col>
								<div style={{ margin: "1% 0 4% 0" }}>Mode of Payment</div>
								<Asyncinput
											name="mode"
											defaultvalue={modeofpayment}
											newstate={setmodeofpayment}
									api="/api/payout/modeofpayment"
									min="1"
									placeholder="modofpayment"
									handle={handleFilterChange}
								/>
							</Col>
						</Row>
						<input
							type="text"
							onChange={handleFilterChange}
							name="investmentID"
							value={investmentID}
							hidden
						></input>

						{/* <input type="text" onChange={handleFilterChange} name="myName"></input>
            <input type="hidden" name="myNames" value="hii" onChange={handleFilterChange}></input> */}
						<Button type="submit">Submit</Button>
					</Form>
				 		</Modal.Body>

					</Modal>
					<div
						style={{ borderBottom: "1px solid #999999", margin: "2% 0 2% 0" }}
					></div>
					{/* </FormWrapper> */}
					<div className="distanceTable">
						<Datatable
							columns={columns}
							filter={filter}
							api="/api/payout/allpayoutdetails"
							method="POST"
							limit={10}
							sortable={true}
						></Datatable>
					</div>
				</Col>
			</Row>
		</div>
	);
	}
	else {
		return (
		<div style={{height:"100vh"}}>
			
			
			<Row style={{height:"inherit"}}>
              						<div style={{ display:"block",marginTop:"7%",marginLeft:"auto",marginRight:"auto",width:"45%"}}>

                <Navbarcomp />
                    </div>
                   <div style={{textAlign:"right",position:"absolute",top:'1%',right:"1%"}}>

      <Sidepanel/>
                    </div>
					
				<div className="a3" style={{marginTop:"5%"}}>Payout Table</div>
					<Modal show={show2} onHide={handleClose2} centered>
        <Modal.Header closeButton>
          <Modal.Title>Investment Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      {/* {new Date(invdata.investmentdate)} */}
        <Table>
        <thead>
        <tr>
          <th>Name</th>
          <th>Detail</th>
        </tr>
      </thead>
          {
            Object.entries(invdata).map((key,value)=>
            {
              var ob = {'object':'','_id':'','customerid':'','status':'','userid':'','bikenumber':'','investmenttype':''}
              
              // var ob = ['object','_id','customerid','status','userid','investmenttype','bikenumber']
              if(key[0].includes('date') || key[0]==='nextpayout')
              {
                let k=new Date(key[1]);
                key[1]=k.getDate()+"-"+k.getMonth()+"-"+k.getFullYear()
              }
              
              if(!(key[0] in ob))
              return(
                <tbody key={i++}>
            <tr>
              
              <td>{(key[0])}</td>
              <td>
                {key[1]}
                {
                  console.log(typeof(key[1]))
                }
                </td>
            </tr>
            </tbody>)
          
          })
        }
       

        </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
              </Modal>
              <div style={{textAlign:"right",margin:"1% 1% 0% 0"}}>

               <Button  variant="primary" onClick={handleShow2}>
                Filter
              </Button>
              </div>
              
               <Modal centered
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >

				        <Modal.Header closeButton>
						<Modal.Title>Payout Filter</Modal.Title>
						</Modal.Header>
						<Modal.Body>
					<Form onSubmit={handleFilterSubmit}>
						<Row style={{ margin: "2% 0 2% 0" }}>
							<Col>
								{/* <Asyncinput api="" min="3" placeholder="newname"/> */}
								<div style={{ margin: "1% 0 4% 0" }}>Payout Id</div>
								<Asyncinput
									name="payoutid"
									api="/api/payout/payoutid"
									min="1"
									placeholder="payoutid"
											handle={handleFilterChange}
											defaultvalue={payoutid}
											newstate={setpayoutid}
								/>
							</Col>

							<Col>
								<div style={{ margin: "1% 0 4% 0" }}>Mode of Payment</div>
								<Asyncinput
									name="mode"
									api="/api/payout/modeofpayment"
									min="1"
									placeholder="modofpayment"
											handle={handleFilterChange}
											defaultvalue={modeofpayment}
											newstate={setmodeofpayment}
								/>
							</Col>
						</Row>
						<input
							type="text"
							onChange={handleFilterChange}
							name="investmentID"
							value={investmentID}
							hidden
						></input>

						{/* <input type="text" onChange={handleFilterChange} name="myName"></input>
            <input type="hidden" name="myNames" value="hii" onChange={handleFilterChange}></input> */}
						<Button type="submit">Submit</Button>
					</Form>
				 		</Modal.Body>

					</Modal>
					<div
						style={{ borderBottom: "1px solid #999999", margin: "2% 0 2% 0" }}
					></div>
					{/* </FormWrapper> */}
					<div className="distanceTable">
						<Datatable
							columns={columns}
							filter={filter}
							api="/api/payout/allpayoutdetails"
							method="POST"
							limit={5}
							sortable={true}
						></Datatable>
					</div>
			</Row>
		</div>
	);
	}
}

export default Allpayout;
