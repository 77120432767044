import React, {useEffect, useState} from "react";
import axios from "axios";
import {Table} from 'react-bootstrap'
 import { ScrollMenu } from 'react-horizontal-scrolling-menu';

function Datatable(props) {
  var [totalRecords, setTotalRecords] = useState(0);
  var [footer,setFooter]=useState({});
  var [data,setData]=useState([]);
  var [offset,setOffset]=useState(0);
  var [totalPages, setTotalPages] = useState(0);
  var [currentPage, setCurrentPage] = useState(0);
  var [pages, setPages]=useState([]);
  var [sort, setSort]=useState({});
  var sortable = props.sortable;
  
  	const [isMobile, setIsMobile] = useState(3);

	const handleResize = () => {
		if (document.documentElement.clientWidth >= 942) {
			setIsMobile(3);
		} else {
			setIsMobile(1);
		}
	};

  	useEffect(() => {
		handleResize();
		
	}, []);
	useEffect(() => {
		window.addEventListener("resize", handleResize);
  });

  function getDefaultSort(){
    if(props.sortable===undefined){
      sortable=false;
    }
    if(sortable){
      var sortObject={},defaultSortPresent=false;
      for(var i of props.columns){
        if(i.defaultSort){
          sortObject["field"]=i.Accessor;
          sortObject["order"]=i.sortOrder || "ASC";
          defaultSortPresent=true;
        }
      }
      if(!defaultSortPresent){
          sortObject["field"]=props.columns[0].Accessor;
          sortObject["order"]="ASC";
      }
      setSort(sortObject);
    }
  }
  useEffect(getDefaultSort,[]);
  var halfPageDisplay=(Math.floor((props.maxPageDisplay-1)/2)) || 5
  function generatePages(totalPages,currentPage){
    let tempPages=[],start=0,end=totalPages;
    if(currentPage-halfPageDisplay>0){
      start = currentPage - halfPageDisplay;
    }
    if(currentPage+halfPageDisplay<totalPages){
      end = currentPage + halfPageDisplay;
    }
    for(let i=start;i<end;i++){
      tempPages.push(i);
    }
    setPages(tempPages);
  }
  function getData() {
    let requestData = {
      offset: offset,
      filters: props.filter,
      limit: props.limit, 
      sortable:sortable,
      sort:sort
    };
    axios({
      method: props.method,
      url: props.api,
      data: requestData,
      withCredentials:true
    }).then(function (response) {
        setTotalRecords(response.data.totalRecords);
        setData(response.data.data);
        if (response.data.footerPresent) {
          setFooter(response.data.footer);
        }
        var tp=Math.ceil(response.data.totalRecords / props.limit),cp=(offset/props.limit)+1;
        setTotalPages(tp);
        setCurrentPage(cp);
        generatePages(tp,cp);
      });
  }
  function changeOffset(e){
    let index = e.currentTarget.dataset.index;
    if(index==="left"){
      if(offset>=0){
        setOffset((currentPage-2)*props.limit);
      }
    }else if(index==="right"){
      if(offset+props.limit<totalRecords){
        setOffset((currentPage)*props.limit);
      }
    }else{
      setOffset(index * props.limit);
    }
  }
  function changeSort(e){
    if(sortable && e.currentTarget.dataset.sortable==="true"){
      let accessor=e.currentTarget.dataset.accessor;
      let sortObject={};
      if(sort["field"]===accessor){
        sortObject["field"] = accessor;
        sortObject["order"] = (sort["order"]==="ASC"?"DESC":"ASC");
      }else{
        sortObject["field"] = accessor;
        sortObject["order"] = "ASC";
      }
      setSort(sortObject);
    }
  }
  useEffect(getData, [offset,sort]);
  useEffect(function () {
    setOffset(0);
    getData();
  },[props.filter]);
  let accessors = props.columns.map((column) => column.Accessor);
  if(isMobile!==3)
  return (
    <div className="t">
      {props.filter.myName}
      <ScrollMenu>

      <Table >
        <thead>
          <tr>
            {props.columns.map((column, index) => (
              <th key={index} data-accessor={column.Accessor} onClick={changeSort} data-sortable={column.sortable}>{column.Header} <SortDisplay sortable={sortable} sort={sort} column={column}></SortDisplay></th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              {accessors.map((entry, index) => (
                <td key={index}>{props.columns[index].displayFunction?props.columns[index].displayFunction(row):row[entry]}</td>
              ))}
            </tr>
          ))}
        </tbody>
        {footer ? (
          <tfoot>
            <tr>
              {accessors.map((entry, index) => (
                <td key={index}>{footer[entry]}</td>
              ))}
            </tr>
          </tfoot>
        ) : null}
        </Table>
      </ScrollMenu>
        
      <div style={{margin:"1% 0 0  0",width:"-webkit-fill-available"}}>
      <div className="pageRangeDisplay">
        {offset>=props.limit?(<i className="fas fa-angle-left pageRangeItems" onClick={changeOffset} data-index={"left"}></i>):null}
        {pages[0]>0?(<><span data-index={0} className="pageRangeItems" onClick={changeOffset}>{1}</span> <span className="pageRangeInterval">...</span></>):null}
        {pages.map(
          function (value) {
            if((currentPage-1)===value){
              return <span key={value} data-index={value} className="pageRangeItems active" onClick={changeOffset}>{value+1} </span>;

            }else{
              return <span key={value} data-index={value} className="pageRangeItems" onClick={changeOffset}>{value+1} </span>;
            }
          }
        )}
        
        {pages[pages.length-1]<totalPages-1?(<><span className="pageRangeInterval">...</span> <span data-index={totalPages-1} className="pageRangeItems" onClick={changeOffset}>{totalPages}</span></>):null}

        {offset+props.limit<totalRecords?(<i className="fas fa-angle-right pageRangeItems" onClick={changeOffset} data-index={"right"}></i>):null}
        
      </div>
      <div className="recordsRangeDisplay">
        <b>
          {offset + 1}-{offset + data.length}
        </b>{" "}
        of <b>{totalRecords}</b>
      </div>
      </div>
    </div>
        

    );
  else
      return (
    <div className="t">
      {props.filter.myName}
    

      <Table >
        <thead>
          <tr>
            {props.columns.map((column, index) => (
              <th key={index} data-accessor={column.Accessor} onClick={changeSort} data-sortable={column.sortable}>{column.Header} <SortDisplay sortable={sortable} sort={sort} column={column}></SortDisplay></th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              {accessors.map((entry, index) => (
                <td key={index}>{props.columns[index].displayFunction?props.columns[index].displayFunction(row):row[entry]}</td>
              ))}
            </tr>
          ))}
        </tbody>
        {footer ? (
          <tfoot>
            <tr>
              {accessors.map((entry, index) => (
                <td key={index}>{footer[entry]}</td>
              ))}
            </tr>
          </tfoot>
        ) : null}
        </Table>
      
        
      <div style={{margin:"1% 0 0  0",width:"-webkit-fill-available"}}>
      <div className="pageRangeDisplay">
        {offset>=props.limit?(<i className="fas fa-angle-left pageRangeItems" onClick={changeOffset} data-index={"left"}></i>):null}
        {pages[0]>0?(<><span data-index={0} className="pageRangeItems" onClick={changeOffset}>{1}</span> <span className="pageRangeInterval">...</span></>):null}
        {pages.map(
          function (value) {
            if((currentPage-1)===value){
              return <span key={value} data-index={value} className="pageRangeItems active" onClick={changeOffset}>{value+1} </span>;

            }else{
              return <span key={value} data-index={value} className="pageRangeItems" onClick={changeOffset}>{value+1} </span>;
            }
          }
        )}
        
        {pages[pages.length-1]<totalPages-1?(<><span className="pageRangeInterval">...</span> <span data-index={totalPages-1} className="pageRangeItems" onClick={changeOffset}>{totalPages}</span></>):null}

        {offset+props.limit<totalRecords?(<i className="fas fa-angle-right pageRangeItems" onClick={changeOffset} data-index={"right"}></i>):null}
        
      </div>
      <div className="recordsRangeDisplay">
        <b>
          {offset + 1}-{offset + data.length}
        </b>{" "}
        of <b>{totalRecords}</b>
      </div>
      </div>
    </div>
        

  );
}

function SortDisplay(props){
  return (
    <>
      {props.sortable ? (
        props.column.sortable != false ? (
          props.column.Accessor === props.sort.field ? (
            props.sort.order === "ASC" ? (
              <i className="fas fa-sort-up"></i>
            ) : (
              <i className="fas fa-sort-down"></i>
            )
          ) : (
            <i className="fas fa-sort"></i>
          )
        ) : null
      ) : null}
    </>
  );
}

export default React.memo(Datatable);
