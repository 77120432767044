import React,{useState,useEffect} from "react";
import {Table, Modal, Row, Col, Button } from 'react-bootstrap'
import instance from '../helpers/instance'

function Newinvestment({setShow3,show3,setShow4,show4,bikeinfoo}) {

    const [state, setState] = useState(1);
     
    const [acdata, setAcdata] = useState({})
    const [bikeinfo, setBikeinfo] = useState(bikeinfoo)
    
    const [show2, setShow2] = useState(false);


    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    // const [show3, setShow3] = useState(show3 || false);

    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);

    const handleSubmit = () => {
      var data = { bikes:state }
      instance.post('/api/investmentdetails/newinvestment',data);
      handleClose3();
      handleShow4();

      
    }

    const bankdetails = async() => {
         let data2 = await instance.get('/api/investmentdetails/accountdetail');
      setAcdata(data2.data);
    }
    useEffect(() => {
        bankdetails();
    }, show4);
    

    
    const valuehandler = (val) =>{

      if(isNaN(val)===false)
      {let num = Number(val);
      let newnum = Number((Math.max(0,Math.min(num,50))));
      setState(newnum)}
    }
    
    return (
        <div>
            <Modal show={show4} onHide={handleClose4} centered>
                <Modal.Header closeButton>
                    <Modal.Title>E Drive Account Details</Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>Name</Col>
                        <Col >{acdata.name}</Col>
                    </Row>
                    <Row>
                        <Col>IFSC</Col>
                        <Col>{acdata.ifsc}</Col>
                    </Row>
                    <Row>
                        <Col>Account Number</Col>
                        <Col>{acdata.accountnumber}</Col>
                    </Row>
                    <Row>
                        <Col>Amount Payable</Col>
                        <Col>{bikeinfo.InvestmentAmount * state}</Col>
                    </Row>

                    <Row style={{marginTop:"2%",fontWeight:"600"}}>
                        Note : Awesome ! You have received the same on your mail.
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose4}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show3} onHide={handleClose3}>
                <Modal.Header closeButton>
                    <Modal.Title>Investment Plan</Modal.Title>
                </Modal.Header>
                <Modal.Body>    
                    <div style={{ textAlign: "center", margin: "0 0 5px 0" }}>
                        Number of Vehicles - {" "}
                        <input type="string"
                            // min={1} max={50}
                            value={state}
                            onChange={e => valuehandler(e.target.value)} />
                    </div>

                    <Table striped bordered hover >
                        <tbody>
                            <tr>
                                <td>IRR</td>
                                <td>{bikeinfo.IRR}%</td>
                            </tr>
                            <tr>
                                <td>Tenure</td>
                                <td>{bikeinfo.Tenure}months</td>
                            </tr>
                            <tr>
                                <td>Investment Amount</td>
                                <td>{bikeinfo.InvestmentAmount * state}</td>
                            </tr>
                            <tr>
                                <td>Monthly Payouts</td>
                                <td>{bikeinfo.monthlypayout * state}</td>
                            </tr>
                            <tr>
                                <td>Total Payment</td>
                                <td>{bikeinfo.TotalPayment * state}</td>
                            </tr>
                            <tr>
                                <td>Total Profit</td>
                                <td>{bikeinfo.TotalProfit * state}</td>
                            </tr>
                  
                        </tbody>
                    </Table>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleSubmit}>Payment</Button>
                    <Button variant="secondary" onClick={handleClose3}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </div>);
}

export default Newinvestment;
